import React from "react";
import Loader from "../../../../common/Loader";
import Modal from "react-modal";
import NewOrderAddressInfo from "./NewOrderAddressInfo";
import NewOrderCCInfo from "./NewOrderCCInfo";
import PhoneInput from "../../../../shared/PhoneInput";
import Radio from "../../../../shared/Radio";
import Select from "../../../../shared/Select";
import { notify } from "react-notify-toast";
import { injectStripe } from "react-stripe-elements";
import _isEqual from "lodash.isequal";
import _cloneDeep from "lodash.clonedeep";
import _set from "lodash.set";

import RafflesApi from "../../../../../services/resources/RafflesApi";
import SystemApi from "../../../../../services/resources/SystemApi";
import { ApiCallErrorMessageHandler } from "../../../../../lib/coc-common-scripts";
import axios from "axios";
import {
  formatCurrency,
  formatDateForInput,
  removeEmptyFromObj,
  validateEmail,
  currencyCodes,
} from "../../../../../lib/utils";
import { RafflePaymentTypes } from "../../../RaffleConsts";

class NewRaffleOrderModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      loading: false,
      cashTypes: [],
      order: {
        address: {
          address1: "",
          address2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        association: "",
        cardHolderFullName: "",
        ccInfoComplete: false,
        cashType: "Cash",
        didDonorPayProcessingFee: false,
        doSendEmail: false,
        email: "",
        firstName: "",
        isPrivate: false,
        isUkGiftAid: false,
        lastName: "",
        orderDate: props.raffleIsActive ? formatDateForInput(new Date()) : "",
        paymentNotes: "",
        paymentType: RafflePaymentTypes.Cash,
        phone: "",
        referrerID: "",
        sellerEnrollmentID: props.enrollmentId,
        source: "Shliach",
        ticketQuantity: "",
        tribute: "",
      },

      orderValidationErrors: [],

      submitAttempted: false,
    };

    this.state.initialState = _cloneDeep(this.state);
  }

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    SystemApi.lists(["cashTypes"]).then((cashTypes) => {
      this.setState(cashTypes);
    });
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onChangeOrder = (name, value, otherUpdates) => {
    let order = _cloneDeep(this.state.order);
    _set(order, name, value);

    if (otherUpdates) {
      Object.keys(otherUpdates).forEach((update) =>
        _set(order, update, otherUpdates[update])
      );
    }

    this.setState({ order });
  };

  onChangeEventOrder = (event) => {
    this.onChangeOrder(
      event.target.name,
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value
    );
  };

  onClose = () => {
    this.setState(this.state.initialState, this.props.close);
  };

  onSave = () => {
    this.setState(
      {
        errorMessage: "",
        submitAttempted: true,
      },
      () => {
        if (this.orderIsIncomplete()) {
          return;
        }

        const { reloadOrders, systemCountries } = this.props;

        const {
          order: { ccInfoComplete, cardHolderFullName, ...orderInfo },
          order: { address, paymentType },
        } = this.state;

        const orderForSubmission = _cloneDeep(orderInfo);

        this.setState(
          {
            loading: true,
          },
          async () => {
            if (paymentType === RafflePaymentTypes.CreditCard) {
              const billingDetails = {
                name: cardHolderFullName,
              };
              const hasAddress =
                address.address1 ||
                address.address2 ||
                address.city ||
                address.state ||
                address.country ||
                address.zip;
              if (hasAddress) {
                billingDetails.address = {
                  line1: address.address1,
                  line2: address.address2,
                  city: address.city,
                  state: address.state,
                  postal_code: address.zip,
                  country: (
                    systemCountries.find(
                      (country) => country.name === address.country
                    ) || {}
                  ).code,
                };
              }

              let { error: paymentMethodError, paymentMethod } =
                await this.props.stripe.createPaymentMethod("card", {
                  billing_details: billingDetails,
                });

              if (paymentMethodError) {
                this.setState({
                  errorMessage:
                    paymentMethodError.message ||
                    "Unable to process cc payment.  Please try again.",
                  loading: false,
                });
                return;
              }

              orderForSubmission.paymentMethodId = paymentMethod.id;
            }

            removeEmptyFromObj(orderForSubmission);

            try {
              let orderResponse = await RafflesApi.createOrder(
                this.apiSignal.token,
                orderForSubmission
              );

              if (orderResponse.requiresAction) {
                //SCA Action Required
                const { error: paymentIntentError, paymentIntent } =
                  await this.props.stripe.handleCardAction(
                    orderResponse.paymentIntentClientSecret
                  );

                if (paymentIntentError) {
                  this.setState({
                    errorMessage:
                      paymentIntentError.message ||
                      "Unable to process cc payment.  Please try again.",
                    loading: false,
                  });
                  return;
                } else {
                  // The card action has been handled. The PaymentIntent can be confirmed again on the server
                  delete orderForSubmission.paymentMethodId;
                  orderForSubmission.paymentIntentId = paymentIntent.id;

                  orderResponse = await RafflesApi.createOrder(
                    this.apiSignal.token,
                    orderForSubmission
                  );
                  // Checking again if still requiresAction for some reason and if so returning error message
                  if (orderResponse.requiresAction) {
                    this.setState({
                      errorMessage:
                        "Unable to process cc payment.  Please try again.",
                      loading: false,
                    });
                    return;
                  }
                }
              }

              notify.show("Your order has been added", "success");
              reloadOrders();
              this.onClose();
            } catch (err) {
              if (!axios.isCancel(err)) {
                this.setState({
                  errorMessage: ApiCallErrorMessageHandler(err),
                  loading: false,
                });
              }
            }
          }
        );
      }
    );
  };

  orderIsIncomplete = () => {
    const {
      cardHolderFullName,
      cashTypeID,
      ccInfoComplete,
      email,
      firstName,
      lastName,
      orderDate,
      paymentType,
      ticketQuantity,
    } = this.state.order;

    return (
      !orderDate ||
      !firstName ||
      !lastName ||
      !email ||
      !ticketQuantity ||
      (paymentType === RafflePaymentTypes.CreditCard &&
        (!cardHolderFullName || !ccInfoComplete)) ||
      (paymentType === RafflePaymentTypes.Cash && !cashTypeID)
    );
  };

  updateOrderValidation = (name, isValid) => {
    const { orderValidationErrors } = this.state;
    this.setState({
      orderValidationErrors: isValid
        ? orderValidationErrors.filter((err) => err !== name)
        : [...orderValidationErrors, name],
    });
  };

  ukGiftAidCheckbox = () => (
    <div className="flex flex-align-center mt-16 raffle-order-checkbox-container">
      <input
        className="custom-checkbox"
        id="uk-gift-aid-checkbox"
        name="isUkGiftAid"
        onChange={this.onChangeEventOrder}
        type="checkbox"
        value={this.props.isUkGiftAid}
      />
      <label
        className="flex-align-center"
        style={{ fontWeight: "400" }}
        htmlFor="uk-gift-aid-checkbox"
      >
        <span>
          Donor is a UK citizen and would like to Gift Aid this donation. Only
          check this if confirmed with donor.
        </span>
      </label>
    </div>
  );

  render() {
    const {
      allowDonorToPayProcessingFee,
      cociCcProcessingFeePercentage,
      currencyCode,
      referrers,
      show,
      systemCountries,
      systemRaffleDonorAssociations,
      ticketPrice,
    } = this.props;

    const {
      errorMessage,
      initialState,
      loading,
      cashTypes,
      order,
      order: {
        address,
        association,
        cardHolderFullName,
        ccInfoComplete,
        didDonorPayProcessingFee,
        doSendEmail,
        email,
        firstName,
        isPrivate,
        lastName,
        orderDate,
        paymentNotes,
        paymentType,
        phone,
        referrerID,
        ticketQuantity,
        tribute,
        cashTypeID,
      },
      orderValidationErrors,
      submitAttempted,
    } = this.state;

    const ticketPriceAmount = ticketQuantity * ticketPrice;

    return (
      <Modal isOpen={show} className="center-modal-container">
        <div className="modal-card card new-order-modal relative">
          <div className="new-order-modal-overflow-container">
            <p className="xl-text fw-700 mb-24">New Order</p>
            <div className="new-order-modal-grid mb-24">
              <div className="flex flex-justify-space flex-align-center mr-24 relative">
                <label className="accent-text small-text">Order Date</label>
                <input
                  type="date"
                  className={`custom-input ${
                    submitAttempted && !orderDate ? "error" : ""
                  }`}
                  name="orderDate"
                  onChange={this.onChangeEventOrder}
                  tabIndex={1}
                  value={orderDate}
                />
              </div>
              <div />
              <div className="flex flex-justify-space flex-align-center mr-24 relative">
                <label className="accent-text small-text">First Name</label>
                <input
                  type="text"
                  className={`custom-input ${
                    submitAttempted && !firstName ? "error" : ""
                  }`}
                  name="firstName"
                  onChange={this.onChangeEventOrder}
                  tabIndex={10}
                  value={firstName}
                />
              </div>
              <div className="flex flex-justify-space flex-align-center relative">
                <label className="accent-text small-text">Qty Tickets</label>
                <input
                  type="number"
                  className={`custom-input ${
                    submitAttempted && !ticketQuantity ? "error" : ""
                  }`}
                  min={1}
                  name="ticketQuantity"
                  onChange={this.onChangeEventOrder}
                  tabIndex={40}
                  value={ticketQuantity}
                />
                {ticketQuantity && (
                  <span className="ml-8">
                    {formatCurrency(ticketPriceAmount, currencyCode)}
                  </span>
                )}
              </div>
              <div className="flex flex-justify-space flex-align-center mr-24 relative">
                <label className="accent-text small-text">Last Name</label>
                <input
                  type="text"
                  className={`custom-input ${
                    submitAttempted && !lastName ? "error" : ""
                  }`}
                  name="lastName"
                  onChange={this.onChangeEventOrder}
                  tabIndex={20}
                  value={lastName}
                />
              </div>
              <div className="flex flex-justify-space flex-align-center relative mb-8">
                <label className="accent-text small-text">Phone</label>
                {/* Note: we do not save phoneCountry for raffle orders.  We are not defaulting a country bec we don't have an address yet when mounting this phone input. */}
                <PhoneInput
                  countries={systemCountries || []}
                  error={orderValidationErrors.indexOf("phone") >= 0}
                  name="phone"
                  validate={(isValid) =>
                    this.updateOrderValidation("phone", isValid)
                  }
                  onChange={(cell) => {
                    this.onChangeOrder("phone", cell);
                  }}
                  tabIndex={50}
                  value={phone}
                  validateCountry={false}
                />
              </div>
              <div className="flex flex-justify-space flex-align-center mr-24 relative">
                <label className="accent-text small-text">Email</label>
                <input
                  type="text"
                  className={`custom-input ${
                    (submitAttempted && !email) ||
                    orderValidationErrors.indexOf("email") >= 0
                      ? "error"
                      : ""
                  }`}
                  name="email"
                  onBlur={(event) => {
                    const isValidEmail =
                      !event.target.value || validateEmail(event.target.value);
                    this.updateOrderValidation(event.target.name, isValidEmail);
                  }}
                  onChange={this.onChangeEventOrder}
                  tabIndex={30}
                  value={email}
                />
                {orderValidationErrors.indexOf("email") >= 0 && (
                  <span className="error-text">Invalid Email Address</span>
                )}
              </div>
              {!!referrers.length && (
                <div className="flex flex-justify-space flex-align-center relative">
                  <label className="accent-text small-text">Team</label>
                  <Select
                    className="custom-input"
                    clearable={true}
                    name="referrerID"
                    onChange={this.onChangeOrder}
                    options={referrers.map((referrer) => ({
                      display: referrer.name,
                      value: referrer.id,
                    }))}
                    tabIndex={60}
                    value={referrerID}
                  />
                </div>
              )}
              <div className="flex flex-justify-space flex-align-center relative">
                <label className="accent-text small-text">Association</label>
                <Select
                  className="custom-input full-width"
                  clearable={true}
                  name="association"
                  onChange={this.onChangeOrder}
                  options={systemRaffleDonorAssociations.map((assoc) => ({
                    display: assoc.displayValue,
                    value: assoc.enumValue,
                  }))}
                  placeholder="Select association"
                  tabIndex={64}
                  value={association}
                />
              </div>
              <div
                className="flex flex-justify-space flex-align-center relative"
                style={{ gridColumnStart: "span 2" }}
              >
                <label className="accent-text small-text">Tribute</label>
                <input
                  className="custom-input"
                  name="tribute"
                  onChange={this.onChangeEventOrder}
                  placeholder="In memory of, etc"
                  tabIndex={65}
                  type="text"
                  value={tribute}
                />
              </div>
            </div>

            <NewOrderAddressInfo
              countries={systemCountries}
              address={address}
              onChange={this.onChangeOrder}
              onChangeEvent={this.onChangeEventOrder}
            />

            <div className="mb-24 new-order-payment-method">
              <p className="fw-700 mb-16">Payment Method</p>
              <Radio
                className="flex"
                name="paymentType"
                onChange={this.onChangeOrder}
                options={[
                  {
                    value: RafflePaymentTypes.Cash,
                    display: (
                      <div>
                        Record donation{" "}
                        <span className="small-text accent-text fw-400 mr-32">
                          Monies already collected
                        </span>
                      </div>
                    ),
                  },
                  {
                    value: RafflePaymentTypes.CreditCard,
                    display: (
                      <div>
                        Save donation{" "}
                        <span className="small-text accent-text fw-400">
                          Collect funds now
                        </span>
                      </div>
                    ),
                  },
                ]}
                tabIndex={130}
                value={paymentType}
              />
              {paymentType === RafflePaymentTypes.CreditCard && (
                <NewOrderCCInfo
                  allowDonorToPayProcessingFee={allowDonorToPayProcessingFee}
                  cardHolderFullName={cardHolderFullName}
                  ccInfoComplete={ccInfoComplete}
                  cociCcProcessingFeePercentage={cociCcProcessingFeePercentage}
                  currencyCode={currencyCode}
                  didDonorPayProcessingFee={didDonorPayProcessingFee}
                  onChange={this.onChangeOrder}
                  onChangeEvent={this.onChangeEventOrder}
                  submitAttempted={submitAttempted}
                  ticketPriceAmount={ticketPriceAmount}
                />
              )}
              {paymentType === RafflePaymentTypes.Cash && (
                <div>
                  <label className="accent-text small-text mr-24">
                    Payment Method
                  </label>
                  <Select
                    className={`custom-input ${
                      submitAttempted && !cashTypeID ? "error" : ""
                    }`}
                    clearable={true}
                    name="cashTypeID"
                    onChange={this.onChangeOrder}
                    options={cashTypes.map((cashType) => {
                      return {
                        display: cashType.name,
                        value: cashType.id,
                      };
                    })}
                    tabIndex={140}
                    value={cashTypeID}
                  />
                </div>
              )}
              <div className="mt-16">
                <label className="accent-text small-text">
                  Payment Notes (optional)
                </label>
                <input
                  type="text"
                  className="custom-input full-width"
                  name="paymentNotes"
                  onChange={this.onChangeEventOrder}
                  placeholder="Payment Notes (optional)"
                  tabIndex={140}
                  value={paymentNotes}
                />
              </div>
              {currencyCode === currencyCodes.GBP && this.ukGiftAidCheckbox()}
            </div>
          </div>
          <div className="raffle-modal-btns flex flex-justify-space flex-align-center mt-24">
            <div>
              <div className="flex flex-align-center custom-checkbox-container raffle-order-checkbox-container">
                <input
                  className="custom-checkbox"
                  id="private-donation-checkbox"
                  onChange={(event) =>
                    this.onChangeOrder("isPrivate", event.target.checked)
                  }
                  type="checkbox"
                  value={isPrivate}
                />
                <label
                  className="flex-align-center"
                  htmlFor="private-donation-checkbox"
                >
                  <span>Do not acknowledge this donation publicly</span>
                </label>
              </div>
              <div className="flex flex-align-center custom-checkbox-container raffle-order-checkbox-container">
                <input
                  className="custom-checkbox"
                  id="email-receipt-checkbox"
                  onChange={(event) =>
                    this.onChangeOrder("doSendEmail", event.target.checked)
                  }
                  type="checkbox"
                  value={doSendEmail}
                />
                <label
                  className="flex-align-center"
                  htmlFor="email-receipt-checkbox"
                >
                  <span>Send email receipt</span>
                </label>
              </div>
            </div>

            {loading ? (
              <div className="modal-btns-loader">
                <Loader />
              </div>
            ) : (
              <div className="relative">
                <button
                  className="btn btn-light btn-medium"
                  onClick={this.onClose}
                  tabIndex={200}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-accent btn-medium ml-16"
                  disabled={
                    orderValidationErrors.length ||
                    _isEqual(initialState.order, order)
                  }
                  onClick={this.onSave}
                  tabIndex={190}
                >
                  {paymentType === RafflePaymentTypes.CreditCard
                    ? "Process Payment"
                    : "Save"}
                </button>
              </div>
            )}
          </div>

          {!loading && errorMessage && (
            <span className="error-message" style={{ right: "16px" }}>
              {errorMessage}
            </span>
          )}
        </div>
      </Modal>
    );
  }
}

export default injectStripe(NewRaffleOrderModal);

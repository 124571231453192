import React from "react";

const columns = ["Person ID", "Name", "Classification", "Email", "Cell"];

export default function OtherPeopleTableHeader() {
  return (
    <div className="other-people-table-header">
      {columns.map((column, index) => (
        <p className="flex flex-align-center" key={index}>
          {column}
        </p>
      ))}
    </div>
  );
}

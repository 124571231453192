import React from "react";
import { browserHistory } from "react-router";
import { formatValuesForForm } from "../../../lib/utils";
import AuthClient from "../../../services/resources/AuthClient";
import AuthService, { PermissionClaims } from "../../../services/AuthService";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts/ApiCallErrorHandler";
import { Formik, Form } from "formik";

import GeneralForm from "./general/GeneralForm";
import Loader from "../Loader";

export default class ChabadHouseForm extends React.PureComponent {
  state = {
    submitted: undefined,
    chabadHouse: {},
    loading: undefined,
    campuses: [],
    submitError: {},
    tabContentShowing: "general",
  };

  componentDidMount() {
    this._ismounted = true;
    const { chabadHouseID } = this.props.routeParams;
    let { tab } = this.props.router.location.query;
    if (chabadHouseID) this.getProfileChabadHouse(chabadHouseID);
    this.getCampuses();
    if (tab === undefined) {
      tab = "general";
      this.navigate(tab);
    } else {
      this.setState({ tabContentShowing: tab });
    }
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  componentDidUpdate(prevProps) {
    const {
      routeParams: { chabadHouseID },
      router: {
        location: {
          query: { tab },
        },
      },
    } = this.props;
    let reload =
      prevProps.routeParams.chabadHouseID !==
      this.props.routeParams.chabadHouseID;
    if (reload) {
      this.getProfileChabadHouse(chabadHouseID);
    }
    if (tab !== this.state.tabContentShowing) {
      this.setState({
        tabContentShowing: tab,
      });
    }
  }

  navigate = (page) => {
    browserHistory.replace(
      `${this.props.router.location.pathname}?tab=${page}`
    );
  };

  getProfileChabadHouse = async (id) => {
    let success = true;
    this.setState({ loading: true });
    const { data } = await AuthClient.get(
      `ChabadHouses/${id}?isHandlingAdminData=true`
    ).catch((err) => {
      success = false;
      return {};
    });
    if (this._ismounted) {
      this.setState({
        chabadHouse: data,
        loading: false,
        success: success,
      });
    }
  };

  getCampuses = async () => {
    const { data } = await AuthClient.get(`Campuses/basic`).catch((err) => {
      return { data: [] };
    });
    if (this._ismounted) {
      this.setState({ campuses: data });
    }
  };

  validate = (values) => {
    const errors = {};
    //validate
    return errors;
  };

  require = (value) => (!value && value !== false ? true : "");

  hasUSAddress = (address) => address && address.country === "USA";

  validateDemographics = (value) => {
    if (value.length < 1) return true;
  };

  validateForm = (values) => {
    let errors = {};
    let inputs = [].slice.call(document.getElementsByClassName("phone-input"));
    let invalidInputs = [];
    inputs.map((input) => {
      let invalidInput = input
        .getElementsByTagName("INPUT")[1]
        .hasAttribute("invalid");
      invalidInputs.push(invalidInput);
      return invalidInputs;
    });
    if (invalidInputs.includes(true)) {
      errors.invalidPhoneNumber = true;
    } else {
      delete errors.invalidPhoneNumber;
    }
    return errors;
  };

  formatValuesForSubmit = (values) => {
    let { campuses, demographics, shluchim, ...chabadHouseValues } = values;

    campuses = campuses.map((c) => {
      return {
        campusID: c.campusName.key || c.campusID,
        campusName: c.campusName.value ? c.campusName.value : c.campusName,
        chabadHouseId: chabadHouseValues.id,
        isFullTime: c.isFullTime,
        isPrimary: c.isPrimary,
        distance: c.distance,
      };
    });
    chabadHouseValues.campuses = campuses;
    //TODO redo demographics as readonly based on shliach and shlucha data
    // demographics = demographics.map((d) => {
    //   if (d.key) {
    //     return {
    //       chabadHouseId: chabadHouseValues.id,
    //       demographic: d.value,
    //     };
    //   } else {
    //     return d;
    //   }
    // });
    // chabadHouseValues.demographics = demographics;

    shluchim = shluchim.map((s) => ({
      chabadHouseID: values.id,
      chabadHouseName: values.name,
      fullName: s.fullName,
      shliachID: s.shliachID,
      shliachPosition: s.shliachPosition ? s.shliachPosition : "Unknown",
    }));
    chabadHouseValues.shluchim = shluchim;

    if (!chabadHouseValues.email?.value) {
      chabadHouseValues.email = null;
    }
    if (!chabadHouseValues.phone?.value) {
      chabadHouseValues.phone = null;
    }
    if (!chabadHouseValues.smsContactMethod?.value) {
      chabadHouseValues.smsContactMethod = null;
    }

    return chabadHouseValues;
  };

  onSubmit = async (values, { resetForm }) => {
    this.setState({ saving: true });
    values = this.formatValuesForSubmit(values);
    const { data, error } = await AuthClient.post(
      `ChabadHouses?isHandlingAdminData=true`,
      values
    ).catch((error) => {
      setTimeout(() => {
        this.setState({ submitted: undefined });
      }, 5000);
      return { error };
    });
    if (error) {
      this.setState({
        saving: false,
        submitted: false,
        submitError: error,
      });
    } else {
      this.setState({
        chabadHouse: data && data.payload,
        loading: false,
        submitted: true,
      });
      setTimeout(() => {
        this.setState({ submitted: undefined });
      }, 3000);
      this.getCampuses();
      setTimeout(() => {
        let updatedValues = formatValuesForForm(this.state.chabadHouse);
        this.props.updateMenuLists(
          this.props.routeParams.schoolID || this.props.campus.id
        );
        resetForm(updatedValues);
        this.setState({ saving: false });
      }, 1500);
    }
    if (!this.props.routeParams.chabadHouseID) {
      window.history.pushState(
        null,
        "",
        `/schools/${this.props.routeParams.schoolID}/chabadHouse/${this.state.chabadHouse.id}`
      );
    }
  };

  render() {
    const { campus, sys } = this.props;
    const {
      chabadHouse,
      loading,
      submitted,
      campuses,
      submitError,
      tabContentShowing,
    } = this.state;
    let setNew = !this.props.routeParams.chabadHouseID;
    let newChabadHouse = {
      name: "",
      slug: "",
      regionID: "",
      address: {
        address1: "",
        address2: "",
        country: "",
        city: "",
        state: "",
        zip: "",
      },
      websiteURL: "",
      messagingServiceSid: "",
      //demographics: [],
      campuses: [
        {
          campusID: campus.id,
          campusName: campus.name,
          isFullTime: "",
          isPrimary: true,
        },
      ],
      email: { type: "Email", value: "" },
      phone: { type: "WorkNumber", value: "" },
      smsContactMethod: { type: "MessagingNumber", value: "" },
      shluchim: [],
      personnel: [],
      hasSleepingAccommodations: "",
      hasRelationshipWithHillel: "",
    };
    let readOnly = false;
    if (!AuthService.UserHasClaim(PermissionClaims.ChabadHouseEdit)) {
      readOnly = true;
    }
    return loading ? (
      <div className="card profile-form-card mr-24">
        <div>
          <Loader />
        </div>
      </div>
    ) : (
      <Formik
        initialValues={
          setNew ? newChabadHouse : formatValuesForForm(chabadHouse)
        }
        validate={this.validateForm}
        onSubmit={this.onSubmit}
      >
        {({
          initialValues,
          values,
          dirty,
          errors,
          touched,
          handleChange,
          setFieldError,
          setFieldValue,
        }) => {
          return (
            <Form style={{ display: "flex", width: "100%" }}>
              <div className="card profile-form-card mr-24">
                <ul className="card-tabs-header profile-card-tabs-header">
                  <li>
                    <p
                      className={
                        tabContentShowing === "general" ? "active" : ""
                      }
                      onClick={() => this.navigate("general")}
                    >
                      General
                    </p>
                  </li>
                  {/* TODO remove tabs altogher */}
                </ul>

                <GeneralForm
                  campuses={campuses}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  sys={sys}
                  touched={touched}
                  // shliachID={shliachID}
                  initialValues={initialValues}
                  values={values}
                />
              </div>

              {readOnly ? null : (
                <div style={{ position: "relative" }}>
                  <div className="profile-save-btn-container">
                    <button
                      className="btn btn-accent profile-save-btn"
                      type="submit"
                      disabled={!dirty ? true : false}
                      onClick={() => {
                        this.setState({ submitted: undefined });
                        this.props.checkForErrors(errors);
                      }}
                    >
                      {this.state.saving ? "Saving..." : "Save"}
                    </button>
                    {submitted === false ? (
                      <p className="error-message">
                        {submitError && ApiCallErrorMessageHandler(submitError)}
                      </p>
                    ) : submitted && !dirty ? (
                      <p className="accent-text-dark">
                        <i className="material-icons">check</i> All changes
                        saved
                      </p>
                    ) : dirty ? (
                      <p className="accent-text-dark">
                        <i className="material-icons">save</i>
                        Unsaved changes
                      </p>
                    ) : null}
                    {this.props.errorMessage &&
                    Object.keys(errors).length > 0 ? (
                      <p className="error-message">
                        Please review all required fields
                      </p>
                    ) : null}
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

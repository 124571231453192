import React from "react";
import { browserHistory } from "react-router";
import ConfirmationModal from "../shared/ConfirmationModal";
import FadeOutErrorMessage from "../shared/FadeOutErrorMessage";
import Loader from "../common/Loader";
import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";
import NumberFormat from "react-number-format";

import RsvpApi from "../../services/resources/RsvpApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";
import { notify } from "react-notify-toast";
import { getFormattedValuesForForm, removeEmptyFromObj } from "../../lib";
import axios from "axios";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";

export default class RsvpSystemSettingsPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      authorized: true,
      errorMessage: "",
      initialRsvpSystem: null,
      isRequestedPageLeave: false,
      loading: true,
      rsvpSystem: null,

      showCancelChangesConfirmationModal: false,
      showSubmitConfirmationModal: false,
      submitAttempted: false,
      submitRsvpSystemErrorMessage: "",
      submitRsvpSystemLoading: false,
    };
  }

  apiSignal = axios.CancelToken.source();
  // incompleteSubmissionErrorMessage = "Please complete required fields";

  componentDidMount() {
    //We are allowing access based on the Read permission, for this phase we are not handling Read-only Admins in the UI, assuming Admin with Read will have Edit as well.  If editing is attempted they will get an error.
    if (!AuthService.UserHasClaim(PermissionClaims.RsvpAdminSettingsRead)) {
      this.setState({ authorized: false });
    }

    const { route, router } = this.props;
    router.setRouteLeaveHook(route, this.onLeave);

    this.getRsvpSystem();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onLeave = () => {
    const { initialRsvpSystem, isRequestedPageLeave, rsvpSystem } = this.state;

    if (!isRequestedPageLeave && !_isEqual(rsvpSystem, initialRsvpSystem)) {
      return "Are you sure you want to leave this page?  Your unsaved changes will be lost.";
    }
  };

  getRsvpSystem = async () => {
    const {
      params: { scheduleId },
    } = this.props;

    this.setState({
      loading: true,
    });

    const newState = {
      loading: false,
    };

    try {
      let rsvpSystem = await RsvpApi.getRsvpSystem(
        this.apiSignal.token,
        scheduleId
      );
      rsvpSystem = getFormattedValuesForForm(rsvpSystem);

      newState.initialRsvpSystem = _cloneDeep(rsvpSystem);
      newState.rsvpSystem = rsvpSystem;

      //populate nav param with correct program schedule id for the RSVP System if no schedule is previously specified and an RSVP System exists
      const {
        params: { scheduleId: prevScheduleId },
      } = this.props;

      if (!prevScheduleId && rsvpSystem && rsvpSystem.programScheduleID) {
        browserHistory.replace(
          `/rsvp/settings/${rsvpSystem.programScheduleID}`
        );
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        const createNewRsvpSystem =
          !scheduleId && (err.response && err.response.status) === 404;
        if (createNewRsvpSystem) {
          //create new RSVP system if none exists for this program schedule
          const newRsvpSystem = {
            programScheduleID: scheduleId,
            defaultPolicy: "",
            defaultSmsWelcomeMessage: "",
          };
          newState.initialRsvpSystem = _cloneDeep(newRsvpSystem);
          newState.rsvpSystem = newRsvpSystem;
        } else {
          newState.errorMessage = ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and RSVP System settings could not be retrieved. Please try again."
          );
        }
      }
    }

    this.setState(newState);
  };

  onChangeRsvpSystem = (name, value, otherUpdates) => {
    let rsvpSystem = _cloneDeep(this.state.rsvpSystem);
    _set(rsvpSystem, name, value);

    if (otherUpdates) {
      Object.keys(otherUpdates).forEach((update) =>
        _set(rsvpSystem, update, otherUpdates[update])
      );
    }

    return new Promise((resolve, reject) => {
      this.setState({ rsvpSystem }, () => {
        resolve();

        //if required fields message is shown, re-validate on change
        // const { submitRsvpSystemErrorMessage } = this.state;
        // if (
        //   submitRsvpSystemErrorMessage &&
        //   submitRsvpSystemErrorMessage === this.incompleteSubmissionErrorMessage
        // ) {
        //   const isValid = this.validateEduProgram();
        //   if (isValid) {
        //     this.setState({
        //       submitRsvpSystemErrorMessage: ""
        //     });
        //   }
        // }
      });
    });
  };

  onChangeRsvpSystemEvt = ({ target: { name, value } }) => {
    return this.onChangeRsvpSystem(name, value);
  };

  onCancelRsvpSystemChanges = () => {
    const { initialRsvpSystem, rsvpSystem } = this.state;
    if (_isEqual(rsvpSystem, initialRsvpSystem)) {
      browserHistory.push(`/rsvp/${rsvpSystem.programScheduleID}`);
      return;
    }

    this.setState({ showCancelChangesConfirmationModal: true });
  };

  cancelRsvpSystemChanges = () => {
    const { rsvpSystem } = this.state;
    this.setState({ isRequestedPageLeave: true }, () =>
      browserHistory.push(`/rsvp/${rsvpSystem.programScheduleID}`)
    );
  };

  onSubmitRsvpSystemSettings = () => {
    this.setState({
      submitAttempted: true,
      submitRsvpSystemErrorMessage: "",
    });

    // const isValid = this.validateEduProgram();
    // if (!isValid) {
    //   this.setState({
    //     submitRsvpSystemErrorMessage: this.incompleteSubmissionErrorMessage
    //   });
    //   return;
    // }

    this.setState({
      showSubmitConfirmationModal: true,
    });
  };

  submitRsvpSystemSettings = async () => {
    this.setState({
      showSubmitConfirmationModal: false,
      submitRsvpSystemErrorMessage: "",
      submitRsvpSystemLoading: true,
    });

    try {
      const rsvpSystemForSubmission = _cloneDeep(this.state.rsvpSystem);
      removeEmptyFromObj(rsvpSystemForSubmission);
      const savedRsvpSystem = await RsvpApi.submitRsvpSystem(
        this.apiSignal.token,
        rsvpSystemForSubmission
      );

      notify.show("Your settings have been saved", "success");
      this.setState({ isRequestedPageLeave: true }, () =>
        browserHistory.push(`/rsvp/${savedRsvpSystem.programScheduleID}`)
      );
      // const rsvpSystem = getFormattedValuesForForm(savedRsvpSystem);
      // this.setState(
      //   {
      //     rsvpSystem: _cloneDeep(rsvpSystem),
      //     initialRsvpSystem: rsvpSystem,
      //     submitRsvpSystemLoading: false,
      //     submitAttempted: false,
      //   },
      //   () => {
      //      notify.show("Your settings have been saved", "success");
      //   }
      // );
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          submitRsvpSystemErrorMessage: ApiCallErrorMessageHandler(err),
          submitRsvpSystemLoading: false,
        });
      }
    }
  };

  //Note - No validation currently.  Leaving this (& related validation code) in so we can add easily if necessary in the future.
  // validateRsvpSystem = () => {
  //   const {
  //     rsvpSystem: { defaultPolicy }
  //   } = this.state;

  //   if (
  //     !defaultPolicy
  //   ) {
  //     return false;
  //   }

  //   return true;
  // };

  render() {
    const {
      params: { scheduleId },
    } = this.props;

    const {
      authorized,
      errorMessage,
      initialRsvpSystem,
      loading,
      rsvpSystem,
      showCancelChangesConfirmationModal,
      showSubmitConfirmationModal,
      submitRsvpSystemErrorMessage,
      submitRsvpSystemLoading,
    } = this.state;

    const {
      defaultPolicy,
      defaultSmsWelcomeMessage,
      id: rsvpSystemId,
      mmsPrice,
      smsPrice,
    } = rsvpSystem || {};

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="rsvp-page page container">
        <ProgramBreadcrumbsHeader
          getPrevPages={(sched) => [
            {
              path: `/rsvp/${sched.scheduleID}`,
              title: `Manage ${sched.scheduleName || sched.programName}`,
            },
          ]}
          pageTitle="Settings"
          scheduleId={scheduleId}
        />
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage || !rsvpSystem ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>{errorMessage}</p>
          </div>
        ) : (
          <div className="card full-width">
            <p className="flex flex-align-center mb-16 xxl-text">
              <i
                className="material-icons link-text mr-16"
                onClick={() => browserHistory.goBack()}
              >
                arrow_back
              </i>
              RSVP System Settings
            </p>
            <div className="course-form">
              <div className="course-form-section">
                <div className="mb-24 mt-24">
                  <div className="flex flex-align-center mb-8">
                    <label className="accent-text-dark">
                      Default SMS Welcome Message
                    </label>
                    <div className="tooltip-container">
                      <i className="material-icons large-text ml-8">info</i>
                      <span className="tooltip">
                        This will be sent to the users who sign up for SMS.
                      </span>
                    </div>
                  </div>
                  <textarea
                    className="custom-input full-width"
                    name="defaultSmsWelcomeMessage"
                    onChange={this.onChangeRsvpSystemEvt}
                    style={{ resize: "none", height: "72px" }}
                    value={defaultSmsWelcomeMessage}
                  />
                </div>
                <div className="mb-24">
                  <div className="flex flex-align-center mb-8">
                    <label className="accent-text-dark">
                      Default COVID-19 Policy
                    </label>
                    <div className="tooltip-container">
                      <i className="material-icons large-text ml-8">info</i>
                      <span className="tooltip">
                        Students will need to agree to this in order to RSVP.
                      </span>
                    </div>
                  </div>
                  <textarea
                    className="custom-input full-width"
                    name="defaultPolicy"
                    onChange={this.onChangeRsvpSystemEvt}
                    style={{ resize: "none", height: "72px" }}
                    value={defaultPolicy}
                  />
                </div>
                <div className="mb-24">
                  <div className="flex flex-align-center mb-12 mobile-block">
                    <div className="flex flex-align-center mb-8">
                      <label className="accent-text-dark">
                        Sms price per segment
                      </label>
                      <div className="tooltip-container">
                        <i className="material-icons large-text ml-8">info</i>
                        <span className="tooltip">
                          All sms messages will be charged this amount per
                          message segment.
                        </span>
                      </div>
                    </div>
                  </div>

                  <NumberFormat
                    allowNegative={false}
                    className="dollar-input custom-input"
                    placeholder="Amount"
                    name="smsPrice"
                    value={smsPrice}
                    onChange={this.onChangeRsvpSystemEvt}
                    thousandSeparator={true}
                  />
                </div>
                <div className="mb-24">
                  <div className="flex flex-align-center mb-12 mobile-block">
                    <div className="flex flex-align-center mb-8">
                      <label className="accent-text-dark">
                        Mms price per segment
                      </label>
                      <div className="tooltip-container">
                        <i className="material-icons large-text ml-8">info</i>
                        <span className="tooltip">
                          All mms messages will be charged this amount per
                          message segment.
                        </span>
                      </div>
                    </div>
                  </div>

                  <NumberFormat
                    allowNegative={false}
                    className="dollar-input custom-input"
                    placeholder="Amount"
                    name="mmsPrice"
                    value={mmsPrice}
                    onChange={this.onChangeRsvpSystemEvt}
                    thousandSeparator={true}
                  />
                </div>
              </div>
            </div>
            <div className="course-form-btns flex flex-justify-space mt-16">
              <button
                className="custom-btn btn-light uppercase-text"
                disabled={submitRsvpSystemLoading}
                onClick={this.onCancelRsvpSystemChanges}
              >
                Cancel
              </button>
              {submitRsvpSystemLoading ? (
                <div className="medium-loader">
                  <Loader />
                </div>
              ) : (
                <React.Fragment>
                  <button
                    className="custom-btn btn-accent uppercase-text"
                    disabled={
                      rsvpSystemId && _isEqual(rsvpSystem, initialRsvpSystem)
                    }
                    onClick={this.onSubmitRsvpSystemSettings}
                  >
                    Save
                  </button>
                  {!!submitRsvpSystemErrorMessage && (
                    <FadeOutErrorMessage
                      message={submitRsvpSystemErrorMessage}
                      onTimeout={() =>
                        this.setState({
                          submitRsvpSystemErrorMessage: "",
                        })
                      }
                    />
                  )}
                </React.Fragment>
              )}
            </div>
            <ConfirmationModal
              cancel={() =>
                this.setState({
                  showCancelChangesConfirmationModal: false,
                  showSubmitConfirmationModal: false,
                })
              }
              cancelText={showCancelChangesConfirmationModal && "No"}
              confirm={
                showCancelChangesConfirmationModal
                  ? this.cancelRsvpSystemChanges
                  : this.submitRsvpSystemSettings
              }
              confirmText={showCancelChangesConfirmationModal && "Yes"}
              message={`Are you sure you want to ${
                showCancelChangesConfirmationModal
                  ? "cancel your changes to RSVP System settings?"
                  : "save changes to RSVP System settings?"
              }
                  `}
              show={
                showCancelChangesConfirmationModal ||
                showSubmitConfirmationModal
              }
            />
          </div>
        )}
      </div>
    );
  }
}

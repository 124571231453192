import React, { forwardRef, useMemo, useRef, useState } from "react";
import parsePhoneNumber from "libphonenumber-js";
import get from "lodash.get";
import {
  InputAdornment,
  MenuItem,
  Select,
  Typography,
  styled,
} from "@material-ui/core";
import {
  defaultCountries,
  FlagEmoji,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import { TextInput } from "./TextInput";
import { getCountryIdByCode, getDefaultCountryCode } from "../../lib";

const InputAdornmentStyled = styled(InputAdornment)(() => ({
  marginRight: "2px",
  "& .MuiSelect-selectMenu": {
    display: "flex",
    alignItems: "center",
  },
  "& .MuiInput-underline:before, .MuiInput-underline:after": {
    borderBottom: "none",
  },
}));

const PhoneInput = React.memo(
  forwardRef(function PhoneInput(
    {
      className,
      countries,
      countryFieldName,
      countryId,
      defaultCountryName,
      disabled,
      error: _error,
      formikErrors,
      formikTouched,
      id,
      label,
      name,
      onBlur,
      onChange,
      onChangeCountry,
      setFieldError: _,
      setFieldValue,
      value,
      validate,
      validateCountry = true,
      ...passProps
    },
    ref
  ) {
    const defaultCountryCode = useMemo(
      () => getDefaultCountryCode(countries, countryId, defaultCountryName),
      [countries, countryId, defaultCountryName]
    );

    const error = useMemo(() => {
      return formikErrors
        ? get(formikErrors, "invalidPhoneNumber") && get(formikTouched, name)
        : _error;
    }, [_error, formikErrors, formikTouched, name]);

    //We need to keep track of the error locally to accommodate immediate validation in formik forms,
    //which don't validate and provide an updated formikErrors prop until the Save button is clicked
    const [localError, setLocalError] = useState(error);

    const supportedCountries = useMemo(() => {
      const supportedCountryCodes = countries.map((c) => c.code.toLowerCase());
      return defaultCountries.filter((c) => {
        const code = c[1];
        return (
          code === defaultCountryCode || // the country corresponding to the defaultCountryCode must be included
          supportedCountryCodes.includes(code)
        );
      });
    }, [countries, defaultCountryCode]);

    // the onChange callback provided to usePhoneInput is called immediately upon input mount, without any manual input change
    // we therefore maintain this ref, which is set to true in the manual change handlers below, to ensure that the initial change callback is disregarded
    const changeActivated = useRef(false);

    const { country, handlePhoneValueChange, inputRef, phone, setCountry } =
      usePhoneInput({
        countries: supportedCountries,
        defaultCountry: defaultCountryCode,
        onChange: ({ country, phone }) => {
          if (changeActivated.current) {
            const phoneCountryId = country
              ? getCountryIdByCode(country, countries)
              : null;
            if (setFieldValue) {
              setFieldValue(name, phone);
              setFieldValue(countryFieldName, phoneCountryId);
            } else {
              onChange(phone, phoneCountryId);
            }

            if (localError) {
              validatePhone(phone, phoneCountryId);
            }
          }
        },
        value,
        disableDialCodeAndPrefix: true,
      });

    const validatePhone = (phone, phoneCountryId = countryId) => {
      let isValid = true;
      if (phone) {
        if (validateCountry && !phoneCountryId) {
          isValid = false;
        } else {
          const parsedPhoneNumber = parsePhoneNumber(
            phone,
            country.toUpperCase()
          );
          isValid = parsedPhoneNumber?.isValid();
        }
      }
      if (validate) {
        validate(isValid);
      }
      setLocalError(!isValid);
      //need to add this for the Formik forms that rely on the input element attributes set here for validation.
      let inputContainers = document.getElementsByClassName("phone-input");
      for (let i = 0; i < inputContainers.length; i++) {
        const input = inputContainers[i].getElementsByTagName("INPUT")[1];
        if (input?.getAttribute("value") === phone) {
          if (!isValid) {
            input.setAttribute("invalid", true);
          } else input.removeAttribute("invalid");
        }
      }
    };

    return (
      <div
        className={`phone-input
                   ${localError ? "error" : ""}
                    ${disabled ? "disabled" : ""}
                    ${className || ""}
                `}
      >
        <TextInput
          id={id || name}
          disabled={disabled}
          label={label}
          name={name}
          onChange={(e) => {
            if (!changeActivated.current) changeActivated.current = true;
            handlePhoneValueChange(e);
          }}
          onBlur={(e) => validatePhone(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") validatePhone(e.target.value);
          }}
          inputRef={inputRef}
          variant="standard"
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornmentStyled position="start">
                <Select
                  disabled={disabled}
                  MenuProps={{
                    style: {
                      height: "300px",
                      width: "360px",
                      top: "10px",
                      left: "-34px",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                  value={country || ""}
                  onChange={(e) => {
                    if (!changeActivated.current)
                      changeActivated.current = true;
                    setCountry(e.target.value);
                  }}
                  renderValue={(value) => <FlagEmoji iso2={value} />}
                >
                  {supportedCountries.map((c) => {
                    const country = parseCountry(c);
                    return (
                      <MenuItem key={country.iso2} value={country.iso2}>
                        <FlagEmoji
                          iso2={country.iso2}
                          style={{
                            height: "24px",
                            marginRight: "8px",
                          }}
                        />
                        <Typography style={{ marginRight: "8px" }}>
                          {country.name}
                        </Typography>
                        <Typography>+{country.dialCode}</Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </InputAdornmentStyled>
            ),
          }}
          type="tel"
          ref={ref}
          value={phone}
          helperText={
            localError && value ? (
              <span className="phone-input-error">Invalid Phone Number</span>
            ) : (
              ""
            )
          }
          {...passProps}
        />
      </div>
    );
  })
);
export default PhoneInput;

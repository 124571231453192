import React from "react";

import Sidebar from "react-sidebar";

import AuthService from "../services/AuthService";
import { OmniSearch } from "./common/OmniSearch";
import { NavMenuContainer } from "./common/NavBar/NavBarContainer";
import Login from "./Auth/Login";

import { ErrorSentry, logRocketIdentifyUser, setSentryUser } from "../lib";
import Notifications from "react-notify-toast";

const mql = window.matchMedia(`(min-width: 800px)`);

export class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mql: mql,
      open: props.open,
      mobileMode: false,
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.toggleSidebarOpen = this.toggleSidebarOpen.bind(this);
  }

  toggleSidebarOpen(event) {
    event.preventDefault();
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
    this.setState({ mql: mql, mobileMode: !mql.matches });

    if (AuthService.isAuthenticated()) {
      const user = AuthService.getCurrentUser();
      setSentryUser(user.id, user.name);
      logRocketIdentifyUser(user.id, user.name);
    }
  }

  componentWillUnmount() {
    this.state.mql.removeListener(this.mediaQueryChanged);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ sidebarOpen: false });
  }

  mediaQueryChanged() {
    this.setState({
      mobileMode: !this.state.mql.matches,
    });
  }

  render() {
    return AuthService.isAuthenticated() ? (
      <ErrorSentry>
        <Sidebar
          sidebar={
            <NavMenuContainer
              toggleSidebarOpen={this.toggleSidebarOpen}
              mobileMode={this.state.mobileMode}
            />
          }
          open={this.state.sidebarOpen}
          docked={!this.state.mobileMode}
          onSetOpen={this.onSetSidebarOpen}
          shadow={false}
          transitions={false}
          styles={{
            sidebar: {
              width: "230px",
              boxShadow: "1px 1px 10px 0 #d9e4ed",
              marginRight: "2em",
              background: "white",
              zIndex: 5,
            },
            overlay: {
              zIndex: 4,
            },
          }}
          contentId="scroll-window"
        >
          <div className="container-fluid">
            <Notifications />

            <div className="row clearfix">
              <OmniSearch
                location={this.props.location}
                toggleSidebarOpen={this.toggleSidebarOpen}
                mobileMode={this.state.mobileMode}
              />
              {React.Children.map(this.props.children, (child) =>
                React.cloneElement(child, {
                  mobileMode: this.state.mobileMode,
                  toggleSidebarOpen: this.toggleSidebarOpen,
                })
              )}
            </div>
          </div>
        </Sidebar>
      </ErrorSentry>
    ) : (
      <ErrorSentry>
        <div className="row">
          <Login />
        </div>
      </ErrorSentry>
    );
  }
}

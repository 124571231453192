import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import PaginatedTable from "../../shared/PaginatedTable";
import OtherPeopleTableFilters from "./OtherPeopleTableFilters";
import OtherPeopleTableHeader from "./OtherPeopleTableHeader";
import OtherPeopleTableRow from "./OtherPeopleTableRow";
import ConfirmationModal from "../../shared/ConfirmationModal";
import ExportCSVButton from "../../shared/ExportCSVButton";
import { Unauthorized } from "../../../lib/coc-common-components";

import StudentsApi from "../../../services/resources/StudentsApi";
import AuthService, { PermissionClaims } from "../../../services/AuthService";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import { formatFullName } from "../../../lib";
import axios from "axios";

const ContactMethodTypes = {
  Email: "email",
  Cell: "cell",
};

function OtherPeoplePage() {
  const [authorization, setAuthorization] = useState({
    view: true,
    edit: false,
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [listParams, setListParams] = useState({
    page: 1,
    results: 25,
    sortBy: "name",
    text: "",
  });

  const [people, setPeople] = useState([]);
  const [totalPeople, setTotalPeople] = useState(0);

  const [contactMethodRemoval, setContactMethodRemoval] = useState({
    errorMessage: "",
    loading: false,
    person: null,
    type: "",
  });

  const apiSignalRef = useRef(axios.CancelToken.source());

  useEffect(() => {
    setAuthorization({
      view: AuthService.UserHasClaim(PermissionClaims.StudentAdminRead),
      edit: AuthService.UserHasClaim(PermissionClaims.StudentAdminEdit),
    });

    const _apiSignal = apiSignalRef.current;
    return () => _apiSignal.cancel();
  }, []);

  const getPeople = useCallback(
    async (
      page = 1,
      results = listParams.results,
      sortBy = listParams.sortBy,
      text = listParams.text
    ) => {
      setLoading(true);
      setErrorMessage("");

      const listParams = { page, results, sortBy, text };
      setListParams(listParams);

      try {
        const response = await StudentsApi.getOtherPeople(
          listParams,
          apiSignalRef.current.token
        );
        setPeople(response.people);
        setTotalPeople(response.totalPeople);
      } catch (err) {
        if (!axios.isCancel(err)) {
          setPeople([]);
          setTotalPeople(0);
          setErrorMessage(ApiCallErrorMessageHandler(err));
        }
      }

      setLoading(false);
    },
    [listParams.results, listParams.sortBy, listParams.text]
  );

  const getPeopleForExport = useCallback(async () => {
    const peopleForExport = await StudentsApi.getOtherPeople(
      { ...listParams, isExport: true },
      apiSignalRef.current.token
    );
    return peopleForExport.people;
  }, [listParams]);

  const filterPeople = useCallback(
    (text) => getPeople(1, undefined, undefined, text),
    [getPeople]
  );

  const confirmContactMethodRemoval = useCallback(async () => {
    setContactMethodRemoval((r) => ({
      ...r,
      errorMessage: "",
      loading: true,
    }));

    try {
      const { person, type } = contactMethodRemoval;
      const contactMethodId =
        type === ContactMethodTypes.Email
          ? person.emailID
          : type === ContactMethodTypes.Cell
          ? person.cellID
          : null;
      await StudentsApi.deleteOtherPersonContactMethod(
        person.id,
        contactMethodId,
        apiSignalRef.current.token
      );
      setContactMethodRemoval((r) => ({
        ...r,
        loading: false,
        person: null,
        type: "",
      }));
      getPeople();
    } catch (err) {
      setContactMethodRemoval((r) => ({
        ...r,
        errorMessage: ApiCallErrorMessageHandler(err),
        loading: false,
      }));
    }
  }, [contactMethodRemoval, getPeople]);

  if (!authorization.view) {
    return <Unauthorized userName={AuthService.getCurrentUser().name} />;
  }

  return (
    <div className="other-people-page">
      <div className="flex flex-align-center flex-justify-space mb-24">
        <p className="xxl-text fw-500">Other People</p>
        <ExportCSVButton
          className="btn custom-btn btn-accent"
          fileName="Other_People"
          getExportData={getPeopleForExport}
          headers={[
            { label: "Person ID", value: "personID" },
            { label: "First name", value: "firstName" },
            { label: "Last name", value: "lastName" },
            { label: "Classification", value: "classificationDisplay" },
            { label: "Email", value: "email" },
            { label: "Cell", value: "cell" },
          ]}
        />
      </div>

      <PaginatedTable
        filterComponent={
          <OtherPeopleTableFilters
            applyFilter={filterPeople}
            filterText={listParams.text}
          />
        }
        loading={loading}
        loadData={getPeople}
        page={listParams.page}
        records={people}
        renderHeader={() => <OtherPeopleTableHeader />}
        renderRow={(person) => (
          <OtherPeopleTableRow
            key={person.id}
            onRemoveEmail={() =>
              setContactMethodRemoval((r) => ({
                ...r,
                person,
                type: ContactMethodTypes.Email,
              }))
            }
            onRemoveCell={() =>
              setContactMethodRemoval((r) => ({
                ...r,
                person,
                type: ContactMethodTypes.Cell,
              }))
            }
            person={person}
            readOnly={!authorization.edit}
          />
        )}
        results={listParams.results}
        showResultsCount={true}
        sortBy={listParams.sortBy}
        success={!errorMessage}
        totalCount={totalPeople}
      />

      {!!contactMethodRemoval.person && (
        <ConfirmationModal
          cancel={() =>
            setContactMethodRemoval({
              errorMessage: "",
              person: null,
              type: "",
            })
          }
          confirm={confirmContactMethodRemoval}
          errorMessage={contactMethodRemoval.errorMessage}
          loading={contactMethodRemoval.loading}
          message={`Are you sure you want to delete the ${
            contactMethodRemoval.type
          } for ${formatFullName(
            contactMethodRemoval.person.firstName,
            contactMethodRemoval.person.lastName
          )}?`}
          show={true}
          title={`Delete ${contactMethodRemoval.type}`}
        />
      )}
    </div>
  );
}

export default memo(OtherPeoplePage);

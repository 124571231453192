import React from "react";
import Modal from "react-modal";
import Loader from "./Loader";

import ContentApi from "../../services/resources/ContentApi";
import axios from "axios";

export default class FilePreviewModal extends React.PureComponent {
  state = {
    error: null,
    fileType: null,
    loading: false,
    secureFileURL: null,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  componentDidMount() {
    if (this.props.show) {
      this.getFileInfo();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.getFileInfo();
    }
  }

  getFileInfo = () => {
    const { isSecure, type, url } = this.props;

    if (isSecure) {
      this.getSecureFileURL();
    } else {
      this.setState({
        fileType: this.getFileType(type, url),
      });
    }
  };

  getSecureFileURL = async () => {
    this.setState({ error: null, loading: true });

    try {
      const { type, url } = this.props;

      const secureFileURL = await ContentApi.getSecureBlobURL(url);
      //secure-file-read test:
      await axios.get(secureFileURL);

      this.setState({
        fileType: this.getFileType(type, secureFileURL),
        loading: false,
        secureFileURL,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          error: err,
          loading: false,
        });
      }
    }
  };

  getFileType(type, url) {
    if (type && this.supportedFileTypes.indexOf(type) >= 0) {
      return type;
    }

    if (url) {
      if (url.indexOf(".pdf") > 0) {
        return "application/pdf";
      } else if (url.indexOf(".html") > 0) {
        return "text/html";
      } else if (url.indexOf(".png") > 0) {
        return "image/png";
      } else if (url.indexOf(".jpeg") > 0 || url.indexOf(".jpg") > 0) {
        return "image/jpeg";
      } else if (url.indexOf(".tif") > 0 || url.indexOf(".tiff") > 0) {
        return "image/tif";
      } else if (url.indexOf(".gif") > 0) {
        return "image/gif";
      } else if (url.indexOf(".bmp") > 0) {
        return "image/bmp";
      }
    }

    return "";
  }

  supportedFileTypes = [
    "application/pdf",
    "text/html",
    "image/png",
    "image/jpeg",
    "image/tif",
    "image/gif",
    "image/bmp",
  ];

  onClose = () => {
    this.setState({
      error: null,
      fileType: null,
      loading: false,
      secureFileURL: null,
    });

    this.props.close();
  };

  render() {
    const {
      isPrivate,
      isSecure,
      previewStyle,
      show,
      title,
      type,
      url,
      withDownload,
    } = this.props;
    const { error, fileType, loading, secureFileURL } = this.state;

    const downloadOnly = !fileType;

    const showDownload = withDownload && !error && !downloadOnly;

    return (
      <Modal
        isOpen={show}
        onRequestClose={this.onClose}
        className={`image-preview-modal life-insurance-image-preview-modal${
          downloadOnly ? " image-preview-modal-download" : ""
        }${error ? " image-preview-modal-error" : ""}`}
      >
        {loading ? (
          <Loader />
        ) : (
          <div
            className="card small-modal"
            {...((isPrivate || isSecure) && { "data-private": true })}
          >
            <div className="flex flex-justify-space flex-align-center mb-24">
              <p className="xl-text fw-500">
                {error ? "Something went wrong" : title}
              </p>
              <div className="flex flex-align-center">
                {showDownload && (
                  <a
                    className="pointer link-text-secondary mr-16"
                    download
                    href={isSecure ? secureFileURL : url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <i className="material-icons">download</i>
                  </a>
                )}
                <i
                  className="material-icons pointer link-text-secondary mr-8"
                  onClick={this.onClose}
                >
                  close
                </i>
              </div>
            </div>
            <div>
              {error ? (
                <div className="accent-text line-height-double">
                  {error.response && error.response.status === 403
                    ? "Sorry, you don't have permission to view this file."
                    : "Sorry, something went wrong and your preview could not be loaded. Please try again."}
                </div>
              ) : downloadOnly ? (
                <p className="line-height-double">
                  File preview unavailable. Click{" "}
                  <a
                    className="link-text"
                    download
                    href={isSecure ? secureFileURL : url}
                  >
                    here
                  </a>{" "}
                  to download.
                </p>
              ) : (
                <object
                  data={isSecure ? secureFileURL : url}
                  style={previewStyle}
                  type={type}
                >
                  <embed src={isSecure ? secureFileURL : url} type={type} />
                </object>
              )}
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

import React from "react";
import { Link, browserHistory } from "react-router";
import { DirectoryFilters } from "../common/DirectoryFilters";
// import { MergeContactsTool } from "../MergeTool/MergeContactsTool";
import { Paginator } from "../common/Paginator";
import {
  Loader,
  /* ModalButton, */ ModalWithButtons,
} from "../../lib/coc-common-components";
import CsvGetAndDownload /* , { ColumnConfig } */ from "../common/CSVGetAndDownload";

import AuthService, { PermissionClaims } from "../../services/AuthService";
import { ContactType } from "../Contact/ContactUtils";
import { Storage, ApiCallErrorHandler } from "../../lib/coc-common-scripts";
import EventsApi from "../../services/resources/EventsApi";
import EventSelector from "../Events/EventSelection/EventSelector";
import StudentsImportModal from "../Student/StudentsImportModal";
import StudentProfileModal from "../Student/studentProfile/StudentProfileModal";
import ExportCSVButton from "../shared/ExportCSVButton";
import StudentsApi from "../../services/resources/StudentsApi";
import moment from "moment";
import queryString from "query-string";
import startCase from "lodash.startcase";

export class SearchDirectory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleResults: [],
      loading: true,
      searched: false,
      totalResults: 0,
      shluchimCount: 0,
      shluchosCount: 0,
      fulltimeCount: 0,
      parttimeCount: 0,
      communityCount: 0,
      chabadCount: 0,
      schoolsCount: 0,
      selectedPage: 1,
      resultsPerPage: +Storage().getItem("pgrPageSize") || 10,
      nameSearch: "",
      gender: "All",
      countries: "All",
      states: "All",
      categories: [],
      showEventEditor: false,
      confirmingSavingEvents: false,
      eventToBulkAdd: {},
      clearFilters: false,
      showCreateStudentModal: false,
      showStudentsImportModal: false,
    };

    this.onChangePage = this.onChangePage.bind(this);
    this.fetchResults = this.fetchResults.bind(this);
    this.requestDataForDownload = this.requestDataForDownload.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.loadContacts = this.loadContacts.bind(this);
    this.bulkSaveEvents = this.bulkSaveEvents.bind(this);
    this.openEventSelector = this.openEventSelector.bind(this);
    this.closeEventEditor = this.closeEventEditor.bind(this);
    this.confirmBulkSaveEvents = this.confirmBulkSaveEvents.bind(this);
  }

  componentDidMount() {
    if (this.props.location.query.q) {
      this.clearFiltersAndFetchData(this.props.location.query.q);
    } else {
      this.loadContacts();
    }
  }

  loadContacts() {
    this.fetchResults(
      this.state.nameSearch,
      this.state.selectedPage,
      this.state.resultsPerPage
    );
  }

  onChangePage(selectedPage, resultsPerPage) {
    this.setState({ selectedPage }, () =>
      this.fetchResults(this.state.nameSearch, selectedPage, resultsPerPage)
    );
  }

  componentWillReceiveProps = async (newProps) => {
    if (newProps.loadContacts === true) {
      await this.loadContacts();
    }
    if (newProps.location.query === this.props.location.query) {
      return;
    }
    this.clearFiltersAndFetchData(newProps.location.query.q);
  };

  /** @param {string} */
  clearFiltersAndFetchData(searchString) {
    this.setState(
      {
        selectedPage: 1,
        nameSearch: searchString,
        gender: "All",
        countries: "All",
        states: "All",
        categories: [],
        yearStartedFrom: 0,
        yearStartedTo: 0,
        clearFilters: true,
      },
      () => {
        this.fetchResults(
          searchString,
          this.state.selectedPage,
          this.state.resultsPerPage
        );
        this.setState({ clearFilters: false });
      }
    );
  }

  handleFilters(val) {
    this.setState(
      {
        selectedPage: 1,
        nameSearch: val.name,
        gender: val.gender,
        countries: val.countries,
        states: val.states,
        categories: val.categories.map((c) => c.value),
        yearStartedFrom: val.yearStartedFrom,
        yearStartedTo: val.yearStartedTo,
        includeInactive: val.includeInactive,
      },
      () =>
        this.fetchResults(
          this.state.nameSearch,
          this.state.selectedPage,
          this.state.resultsPerPage
        )
    );
  }

  fetchResults(query, selectedPage, resultsPerPage) {
    this.setState({ loading: true });
    if (this.props.searchContactType === "shliach") {
      this.fetchShluchim(query, selectedPage, resultsPerPage);
    } else {
      this.fetchStudents(query, selectedPage, resultsPerPage);
    }
  }

  fetchShluchim(query, selectedPage, resultsPerPage) {
    const startingOffset = (selectedPage - 1) * resultsPerPage;
    const maxResults = resultsPerPage;
    this.props
      .fetchResults(query, startingOffset, maxResults, {
        contactType: this.props.searchContactType,
        returnNamesOnly: false,
        filterCountry: this.state.countries,
        filterState: this.state.states,
        filterCategories: this.state.categories,
        filterGender: this.state.gender,
        filterYearStartedFrom: this.state.yearStartedFrom,
        filterYearStartedTo: this.state.yearStartedTo,
        includeInactive: this.state.includeInactive,
      })
      .then((response) => {
        this.setState({
          visibleResults: response.data.results,
          totalResults: response.data.count,
          shluchimCount: response.data.shluchimCount,
          shluchosCount: response.data.shluchosCount,
          fulltimeCount: response.data.fulltimeCount,
          parttimeCount: response.data.parttimeCount,
          communityCount: response.data.communityCount,
          chabadCount: response.data.chabadCount,
          schoolsCount: response.data.schoolsCount,
          loading: false,
          searched: true,
        });
        if (this.props.setParentState) {
          this.props.setParentState({ shluchimCount: response.data.count });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }

  fetchStudents(query, selectedPage, resultsPerPage) {
    const startingOffset = (selectedPage - 1) * resultsPerPage;
    const maxResults = resultsPerPage;
    this.props
      .fetchResults(query, startingOffset, maxResults, {
        contactType: this.props.searchContactType,
        returnNamesOnly: false,
        filterCountry: this.state.countries,
        filterState: this.state.states,
        includeInactive: this.state.includeInactive,
      })
      .then((response) => {
        this.setState({
          visibleResults: response.data.results,
          totalResults: response.data.count,
          loading: false,
          searched: true,
        });
        if (this.props.setParentState) {
          this.props.setParentState({ studentsCount: response.data.count });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }
  /** @returns {AxiosPromise} */
  requestDataForDownload() {
    var ids = this.props.contactsToMerge.map((c) => c.id);
    this.props.cleanContactsToMerge();
    return this.props.fetchCsvDownload(this.state.nameSearch, {
      contactType: this.props.searchContactType,
      filterCountry: this.state.countries,
      filterState: this.state.states,
      filterGender: this.state.gender,
      filterCategories: this.state.categories,
      filterContactIds: ids,
      filterYearStartedFrom: +this.state.yearStartedFrom,
      filterYearStartedTo: +this.state.yearStartedTo,
      includeInactive: this.state.includeInactive,
    });
  }

  setLoading(value) {
    this.setState({ loading: value });
  }
  /** @returns {Array<ColumnConfig>} */
  getDownloadHeaders() {
    if (this.props.searchContactType === "student") {
      return [
        {
          labelGroup: "Name",
          columnId: "firstName",
          header: "First Name",
        },
        {
          labelGroup: "Name",
          columnId: "lastName",
          header: "Last Name",
        },
        {
          columnId: "contactMethodsEmail",
          header: "Email",
          transform: (contactMethods) => {
            const emails = contactMethods.filter(
              (cm) =>
                cm.type === "EmailAddress" &&
                cm.value.indexOf("proxymail.facebook.com") < 0
            );
            if (emails.length > 0) {
              return emails[0].value;
            }
            return "";
          },
        },
        {
          columnId: "contactMethodsPhone",
          header: "Phone",
          transform: (contactMethods) =>
            [
              ...contactMethods.filter((cm) => cm.type === "CellPhoneNumber"),
              ...contactMethods.filter((cm) => cm.type === "PhoneNumber"),
            ]
              .map((p) => p.value)
              .join(", ") || "",
        },
        {
          columnId: "studentCampuses",
          header: "School",
          transform: (campuses) =>
            (campuses.length > 0 && campuses[0].name) || "",
        },
      ];
    } else {
      const columnCategories = {
        Shliach: "Shliach",
        Spouse: "Spouse",
        PersonalInfo: "Personal Info",
        ChabadHouse: "Chabad House",
        School: "School",
      };

      return [
        // shliach
        {
          columnId: "id",
          header: "ID",
          category: columnCategories.Shliach,
        },
        {
          labelGroup: "Name",
          columnId: "firstName",
          header: "First Name",
          category: columnCategories.Shliach,
        },
        {
          labelGroup: "Name",
          columnId: "lastName",
          header: "Last Name",
          category: columnCategories.Shliach,
        },
        {
          columnId: "nickName",
          header: "Called By Name",
          category: columnCategories.Shliach,
        },
        {
          columnId: "shliachTitle",
          header: "Title",
          visible: false,
          dataField: "title",
          category: columnCategories.Shliach,
        },
        {
          columnId: "hebrewName",
          header: "Hebrew Name",
          visible: false,
          category: columnCategories.Shliach,
        },
        {
          columnId: "gender",
          header: "Gender",
          visible: false,
          category: columnCategories.Shliach,
        },
        {
          columnId: "dob",
          header: "Birthday",
          visible: false,
          transform: (dob) => (dob ? moment.utc(dob).format("YYYY-MM-DD") : ""),
          category: columnCategories.Shliach,
        },
        {
          columnId: "beforeSunset",
          header: "Before Sunset",
          visible: false,
          transform: (sunset) => (sunset ? "Yes" : "No"),
          category: columnCategories.Shliach,
        },
        {
          columnId: "hebrewDOB",
          header: "Hebrew Birthday",
          visible: false,
          category: columnCategories.Shliach,
        },
        {
          columnId: "category",
          header: "Category",
          visible: false,
          category: columnCategories.Shliach,
        },
        {
          columnId: "primaryEmails",
          dataField: "contactMethods",
          header: "Primary Email",
          transform: (contactMethods) => {
            const emails = contactMethods.filter(
              (cm) =>
                cm.type === "EmailAddress" &&
                cm.primary &&
                cm.value.indexOf("proxymail.facebook.com") < 0
            );
            if (emails.length > 0) {
              return emails[0].value;
            }
            return "";
          },
          category: columnCategories.Shliach,
        },
        {
          columnId: "otherEmails",
          dataField: "contactMethods",
          header: "Other Emails",
          visible: false,
          transform: (contactMethods) =>
            [
              ...contactMethods.filter(
                (cm) =>
                  cm.type === "EmailAddress" &&
                  !cm.primary &&
                  cm.value.indexOf("proxymail.facebook.com") < 0
              ),
            ]
              .map((p) => p.value)
              .join(", ") || "",
          category: columnCategories.Shliach,
        },
        {
          columnId: "mobilePhone",
          dataField: "contactMethods",
          header: "Mobile Phone",
          transform: (contactMethods) =>
            [...contactMethods.filter((cm) => cm.type === "CellPhoneNumber")]
              .map((p) => p.value)
              .join(", ") || "",
          category: columnCategories.Shliach,
        },
        {
          columnId: "homePhone",
          dataField: "contactMethods",
          header: "Home Phone",
          transform: (contactMethods) =>
            [...contactMethods.filter((cm) => cm.type === "PhoneNumber")]
              .map((p) => p.value)
              .join(", ") || "",
          category: columnCategories.Shliach,
        },
        // spouse
        {
          columnId: "spouseShliachId",
          header: "Spouse ID",
          visible: false,
          dataField: "spouse",
          transform: (spouse) => (spouse && spouse.id) || "",
          category: columnCategories.Spouse,
        },
        {
          labelGroup: "Spouse Name",
          columnId: "spouseFirstName",
          header: "Spouse First Name",
          visible: false,
          dataField: "spouse",
          transform: (spouse) => (spouse && spouse.firstName) || "",
          category: columnCategories.Spouse,
        },
        {
          labelGroup: "Spouse Name",
          columnId: "spouseLastName",
          header: "Spouse Last Name",
          visible: false,
          dataField: "spouse",
          transform: (spouse) => (spouse && spouse.lastName) || "",
          category: columnCategories.Spouse,
        },
        {
          columnId: "spouseNickName",
          header: "Spouse Called By Name",
          visible: false,
          dataField: "spouse",
          transform: (spouse) => (spouse && spouse.nickName) || "",
          category: columnCategories.Spouse,
        },
        {
          columnId: "spouseTitle",
          header: "Spouse Title",
          visible: false,
          dataField: "spouse",
          transform: (spouse) => (spouse && spouse.title) || "",
          category: columnCategories.Spouse,
        },
        {
          columnId: "spouseHebrewName",
          header: "Spouse Hebrew Name",
          visible: false,
          dataField: "spouse",
          transform: (spouse) => (spouse && spouse.hebrewName) || "",
          category: columnCategories.Spouse,
        },
        {
          columnId: "spouseGender",
          header: "Spouse Gender",
          visible: false,
          dataField: "spouse",
          transform: (spouse) => (spouse && spouse.gender) || "",
          category: columnCategories.Spouse,
        },
        {
          columnId: "spouseDob",
          header: "Spouse Birthday",
          visible: false,
          dataField: "spouse",
          transform: (spouse) =>
            spouse && spouse.dob ? moment.utc(spouse).format("YYYY-MM-DD") : "",
          category: columnCategories.Spouse,
        },
        {
          columnId: "spouseBeforeSunset",
          header: "Spouse Before Sunset",
          visible: false,
          dataField: "spouse",
          transform: (spouse) => (spouse && spouse.beforeSunset ? "Yes" : "No"),
          category: columnCategories.Spouse,
        },
        {
          columnId: "spouseHebrewDOB",
          header: "Spouse Hebrew Birthday",
          visible: false,
          dataField: "spouse",
          transform: (spouse) => (spouse && spouse.hebrewDOB) || "",
          category: columnCategories.Spouse,
        },
        {
          columnId: "spouseCategory",
          header: "Spouse Category",
          visible: false,
          dataField: "spouse",
          transform: (spouse) => (spouse && spouse.category) || "",
          category: columnCategories.Spouse,
        },
        {
          columnId: "spousePrimaryEmails",
          dataField: "spouse",
          header: "Spouse Primary Email",
          transform: (spouse) => {
            const emails = ((spouse && spouse.contactMethods) || []).filter(
              (cm) =>
                cm.type === "EmailAddress" &&
                cm.primary &&
                cm.value.indexOf("proxymail.facebook.com") < 0
            );
            if (emails.length > 0) {
              return emails[0].value;
            }
            return "";
          },
          category: columnCategories.Spouse,
        },
        {
          columnId: "spouseOtherEmails",
          dataField: "spouse",
          header: "Spouse Other Emails",
          visible: false,
          transform: (spouse) =>
            [
              ...((spouse && spouse.contactMethods) || []).filter(
                (cm) =>
                  cm.type === "EmailAddress" &&
                  !cm.primary &&
                  cm.value.indexOf("proxymail.facebook.com") < 0
              ),
            ]
              .map((p) => p.value)
              .join(", ") || "",
          category: columnCategories.Spouse,
        },
        {
          columnId: "spouseMobilePhone",
          dataField: "spouse",
          header: "Spouse Mobile Phone",
          transform: (spouse) =>
            [
              ...((spouse && spouse.contactMethods) || []).filter(
                (cm) => cm.type === "CellPhoneNumber"
              ),
            ]
              .map((p) => p.value)
              .join(", ") || "",
          category: columnCategories.Spouse,
        },
        {
          columnId: "spouseHomePhone",
          dataField: "spouse",
          header: "Spouse Home Phone",
          transform: (spouse) =>
            [
              ...((spouse && spouse.contactMethods) || []).filter(
                (cm) => cm.type === "PhoneNumber"
              ),
            ]
              .map((p) => p.value)
              .join(", ") || "",
          category: columnCategories.Spouse,
        },
        // family/other
        {
          columnId: "shliachChildren",
          header: "Children",
          visible: false,
          dataField: "children",
          transform: (children) =>
            (children || []).map((c) => c.firstName).join(", "),
          category: columnCategories.PersonalInfo,
        },
        {
          columnId: "shliachDateStartedOnShlichus",
          dataField: "dateStartedOnShlichus",
          header: "Date Started Shlichus",
          visible: false,
          transform: (date) =>
            date ? moment.utc(date).format("YYYY-MM-DD") : "",
          category: columnCategories.PersonalInfo,
        },
        {
          columnId: "shliachDateStartedOnCoci",
          dataField: "dateStartedCOCI",
          header: "Date Started Coci",
          visible: false,
          transform: (date) =>
            date ? moment.utc(date).format("YYYY-MM-DD") : "",
          category: columnCategories.PersonalInfo,
        },
        {
          labelGroup: "Home Address",
          columnId: "homeAddress",
          header: "Home Address Line 1",
          visible: false,
          transform: (address) => (address && address.addressLine1) || "",
          category: columnCategories.PersonalInfo,
        },
        {
          labelGroup: "Home Address",
          columnId: "homeAddress",
          header: "Line 2",
          visible: false,
          transform: (address) => (address && address.addressLine2) || "",
          category: columnCategories.PersonalInfo,
        },
        {
          labelGroup: "Home Address",
          columnId: "homeAddress",
          header: "City",
          visible: false,
          transform: (address) => (address && address.city) || "",
          category: columnCategories.PersonalInfo,
        },
        {
          labelGroup: "Home Address",
          columnId: "homeAddress",
          header: "ZIP",
          visible: false,
          transform: (address) => (address && address.postalCode) || "",
          category: columnCategories.PersonalInfo,
        },
        {
          labelGroup: "Home Address",
          columnId: "homeAddress",
          header: "State",
          visible: false,
          transform: (address) => (address && address.state) || "",
          category: columnCategories.PersonalInfo,
        },
        {
          labelGroup: "Home Address",
          columnId: "homeAddress",
          header: "Country",
          visible: false,
          transform: (address) => (address && address.country) || "",
          category: columnCategories.PersonalInfo,
        },
        {
          columnId: "isActive",
          dataField: "isActive",
          header: "Status",
          transform: (isActive) => (isActive ? "Active" : "Archived"),
          category: columnCategories.PersonalInfo,
        },
        // chabad house
        {
          columnId: "mosadId",
          dataField: "mosad",
          header: "Mosad ID",
          visible: false,
          transform: (mosad) => (mosad && mosad.id) || "",
          category: columnCategories.ChabadHouse,
        },
        {
          columnId: "mosadName",
          dataField: "mosad",
          header: "Mosad Name",
          visible: false,
          transform: (mosad) => (mosad && mosad.name) || "",
          category: columnCategories.ChabadHouse,
        },
        {
          columnId: "mosadPhone",
          dataField: "mosad",
          header: "Mosad Phone",
          visible: false,
          transform: (mosad) =>
            mosad && mosad.phone
              ? mosad.phone + (mosad.ext ? " ext: " + mosad.ext : "")
              : "",
          category: columnCategories.ChabadHouse,
        },
        {
          labelGroup: "Mosad Address",
          columnId: "mosad",
          header: "Mosad Address Line 1",
          visible: false,
          transform: (mosad) =>
            (mosad && mosad.address && mosad.address.addressLine1) || "",
          category: columnCategories.ChabadHouse,
        },
        {
          labelGroup: "Mosad Address",
          columnId: "mosad",
          header: "Mosad Line 2",
          visible: false,
          transform: (mosad) =>
            (mosad && mosad.address && mosad.address.addressLine2) || "",
          category: columnCategories.ChabadHouse,
        },
        {
          labelGroup: "Mosad Address",
          columnId: "mosad",
          header: "Mosad City",
          visible: false,
          transform: (mosad) =>
            (mosad && mosad.address && mosad.address.city) || "",
          category: columnCategories.ChabadHouse,
        },
        {
          labelGroup: "Mosad Address",
          columnId: "mosad",
          header: "Mosad ZIP",
          visible: false,
          transform: (mosad) =>
            (mosad && mosad.address && mosad.address.postalCode) || "",
          category: columnCategories.ChabadHouse,
        },
        {
          labelGroup: "Mosad Address",
          columnId: "mosad",
          header: "Mosad State",
          visible: false,
          transform: (mosad) =>
            (mosad && mosad.address && mosad.address.state) || "",
          category: columnCategories.ChabadHouse,
        },
        {
          labelGroup: "Mosad Address",
          columnId: "mosad",
          header: "Mosad Country",
          visible: false,
          transform: (mosad) =>
            (mosad && mosad.address && mosad.address.country) || "",
          category: columnCategories.ChabadHouse,
        },
        {
          columnId: "mosadYearStarted",
          dataField: "mosad",
          header: "Mosad Year Started",
          visible: false,
          transform: (mosad) => mosad && mosad.yearStarted,
          category: columnCategories.ChabadHouse,
        },
        // school
        {
          columnId: "school",
          dataField: "campuses",
          header: "School",
          transform: (campuses) =>
            (campuses && campuses.map((p) => p.name).join(", ")) || "",
          category: columnCategories.School,
        },
      ];
    }
  }
  /** @returns {string} */
  getFileName() {
    const fileName =
      "Directory" +
      (this.props.searchContactType === "shliach" ? "Shluchim" : "Students");
    return (
      fileName +
      (this.state.nameSearch ? "_" + this.state.nameSearch : "") +
      ".csv"
    );
  }
  /** @returns {string} */
  getAddNewURL() {
    const url =
      this.props.searchContactType === "shliach" ? "shluchim" : "students";
    return "/" + url + "/new";
  }

  openEventSelector() {
    this.setState({ showEventEditor: true });
  }

  closeEventEditor() {
    this.setState({ showEventEditor: false });
  }

  confirmAddEventsButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "Confirm",
      className: "btn btn-danger",
      onClick: () => this.bulkSaveEvents(this.state.eventToBulkAdd),
    });
    buttons.push({
      text: "Cancel",
      className: "btn btn-primary",
      onClick: () =>
        this.setState({
          showEventEditor: false,
          confirmingSavingEvents: false,
        }),
    });
    return buttons;
  }

  messageComponent() {
    const { eventToBulkAdd } = this.state;
    const { contactsToMerge } = this.props;
    return (
      <div>
        <dl>
          <dt>Event category:</dt>
          <dd>{eventToBulkAdd.categoryName}</dd>
          <dt>Event type:</dt>
          <dd>{eventToBulkAdd.typeName}</dd>
          <dt>Occurred on:</dt>
          <dd>{eventToBulkAdd.occurredAt.format("MM/DD/YYYY")}</dd>
          {Object.keys(eventToBulkAdd.metadata)
            .filter((k) => k !== "occurredAt")
            .map((k) => (
              <div key={k}>
                <dt>{startCase(k)}:</dt>
                <dd>{eventToBulkAdd.metadata[k]}</dd>
              </div>
            ))}
          <dt>Contacts:</dt>
          <dd
            style={{
              maxHeight: "150px",
              overflow: contactsToMerge.length < 7 ? "auto" : "scroll",
            }}
          >
            {contactsToMerge.map((c) => (
              <div key={c.id}> {c.firstName + " " + c.lastName}</div>
            ))}
          </dd>
        </dl>
      </div>
    );
  }

  confirmBulkSaveEvents(eventData) {
    let occurredAt =
      eventData.metadata.occurredAt || eventData.occurredAt || moment.utc();
    eventData.occurredAt = occurredAt;
    eventData.metadata = Object.assign({}, eventData.metadata, { occurredAt });
    this.setState({
      showEventEditor: false,
      confirmingSavingEvents: true,
      eventToBulkAdd: eventData,
    });
  }

  bulkSaveEvents(eventData) {
    EventsApi.createBulk(
      eventData,
      this.props.contactsToMerge.map((c) => c.id)
    )
      .then(() => {
        this.setState(
          {
            showEventEditor: false,
            confirmingSavingEvents: false,
            eventToBulkAdd: {},
          },
          () => this.props.cleanContactsToMerge()
        );
      })
      .catch((err) => {
        this.setState({
          showEventEditor: false,
          confirmingSavingEvents: false,
          eventToBulkAdd: {},
        });
        ApiCallErrorHandler(err, true);
      });
  }

  getVisibleResults = () => {
    return this.state.visibleResults;
  };

  getResultsPerPage = () => {
    return this.state.resultsPerPage;
  };

  render() {
    const hasEditPermission = AuthService.UserHasClaim(
      PermissionClaims.DirectoryEdit
    );
    const hasMergePermission = AuthService.UserHasClaim(
      PermissionClaims.StudentMerge
    );

    const { searchContactType, contactsToMerge } = this.props;

    return (
      <div className="list-container">
        <div className="flex flex-align-center flex-justify-space">
          <h1>{searchContactType === "student" ? "Students" : "Summary"}</h1>

          {searchContactType === "student" && (
            <div className="flex flex-align-center">
              {AuthService.UserHasClaim(PermissionClaims.StudentEdit) && (
                <React.Fragment>
                  <button
                    className="btn btn-primary ml-16"
                    onClick={() =>
                      this.setState({ showCreateStudentModal: true })
                    }
                  >
                    Create
                  </button>
                  <StudentProfileModal
                    close={() =>
                      this.setState({ showCreateStudentModal: false })
                    }
                    onSubmit={this.loadContacts}
                    show={this.state.showCreateStudentModal}
                  />
                  <button
                    className="btn btn-primary ml-16"
                    onClick={() =>
                      this.setState({ showStudentsImportModal: true })
                    }
                  >
                    Import
                  </button>
                  <StudentsImportModal
                    close={() =>
                      this.setState({ showStudentsImportModal: false })
                    }
                    reloadStudents={this.loadContacts}
                    show={this.state.showStudentsImportModal}
                  />
                </React.Fragment>
              )}
              <ExportCSVButton
                className="btn btn-primary flex flex-align-center ml-16"
                fileName="Students"
                getExportData={StudentsApi.getStudentsForExport}
                headers={[
                  { label: "ID", value: "studentID" },
                  { label: "First Name", value: "studentFirstName" },
                  { label: "Last Name", value: "studentLastName" },
                  { label: "Email", value: "email" },
                  { label: "Phone", value: "cell" },
                  { label: "Gender", value: "gender" },
                  {
                    label: "Dob",
                    value: ({ dob, isDobEstimated }) =>
                      dob && !isDobEstimated
                        ? moment(dob).format("MM/DD/YYYY")
                        : "",
                  },
                  { label: "Age", value: "age" },
                  {
                    label: "Is Jewish",
                    value: ({ isJewish }) =>
                      isJewish ? "Yes" : isJewish === false ? "No" : "",
                  },
                  {
                    label: "Date Created",
                    value: ({ dateCreated }) =>
                      dateCreated
                        ? moment(dateCreated).format("MM/DD/YYYY")
                        : "",
                  },
                  { label: "Hebrew name", value: "hebrewName" },
                  { label: "Campus", value: "campusName" },
                  {
                    label: "Demographic",
                    value: ({ classDisplay }) =>
                      classDisplay !== "Unknown" ? classDisplay : "",
                  },
                  { label: "Graduation Year", value: "graduationYear" },
                  { label: "Major", value: "major" },
                  { label: "Double Major", value: "doubleMajor" },
                  {
                    label: "Consented to Marketing Contact",
                    value: ({ didConsentToMarketingContact }) =>
                      didConsentToMarketingContact
                        ? "Yes"
                        : didConsentToMarketingContact === false
                        ? "No"
                        : "",
                  },
                  {
                    label: "Consented to Usage of Quotes for Marketing",
                    value: ({ didConsentToMarketingQuotes }) =>
                      didConsentToMarketingQuotes
                        ? "Yes"
                        : didConsentToMarketingQuotes === false
                        ? "No"
                        : "",
                  },
                  { label: "Address 1", value: "address1" },
                  { label: "Address 2", value: "address2" },
                  { label: "City", value: "city" },
                  { label: "State", value: "state" },
                  { label: "Country", value: "country" },
                  { label: "Zip", value: "zip" },
                ]}
                title="Export All Students"
              />
              {hasMergePermission && (
                <button
                  className="btn btn-secondary btn-md ml-16"
                  onClick={() => {
                    browserHistory.push(`/students/merge-suggestions`);
                  }}
                >
                  Show Duplicate Suggestions
                </button>
              )}
              {AuthService.UserHasClaim(PermissionClaims.StudentAdminRead) && (
                <button
                  className="btn btn-secondary btn-md ml-16"
                  onClick={() => {
                    browserHistory.push(`/other-people`);
                  }}
                >
                  View Other People
                </button>
              )}
            </div>
          )}
        </div>
        <div className="flex" style={{ position: "relative" }}>
          <div className="mb-16">
            {searchContactType === "shliach" && (
              <div className="shluchim-results-summary">
                <div className="shluchim-results-summary-item">
                  <i className="icon icon-results">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                  <span className="shluchim-results-summary-desc">
                    <label className="control-label">Results</label>
                    {contactsToMerge.length > 0 ? (
                      <span>
                        <span className="select-results">
                          {contactsToMerge.length}
                        </span>{" "}
                        /{" "}
                      </span>
                    ) : (
                      ""
                    )}
                    {this.state.totalResults}
                  </span>
                </div>
                {this.state.gender === "All" && (
                  <div className="shluchim-results-summary-item">
                    <i className="icon icon-shliach">
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                    <span className="shluchim-results-summary-desc">
                      <label className="control-label">Shluchim</label>
                      {this.state.shluchimCount}
                    </span>
                  </div>
                )}
                {this.state.gender === "All" && (
                  <div className="shluchim-results-summary-item">
                    <i className="icon icon-shlucha">
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                    <span className="shluchim-results-summary-desc">
                      <label className="control-label">Shluchos</label>
                      {this.state.shluchosCount}
                    </span>
                  </div>
                )}
                <div className="shluchim-results-summary-item">
                  <span className="shluchim-results-summary-desc">
                    <label className="control-label">Full Time</label>
                    {this.state.fulltimeCount}
                  </span>
                  <span className="shluchim-results-summary-desc">
                    <label className="control-label">Part Time</label>
                    {this.state.parttimeCount}
                  </span>
                  <span className="shluchim-results-summary-desc">
                    <label className="control-label">Community</label>
                    {this.state.communityCount}
                  </span>
                </div>
                <div className="shluchim-results-summary-item">
                  <i className="icon icon-school">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                  <span className="shluchim-results-summary-desc">
                    <label className="control-label">Schools</label>
                    {this.state.schoolsCount}
                  </span>
                </div>
                <div className="shluchim-results-summary-item">
                  <i className="icon icon-chabad">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                  <span className="shluchim-results-summary-desc">
                    <label className="control-label">Chabad Houses</label>
                    {this.state.chabadCount}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="col-sm-5" />
          {!this.props.isAdvancedSearch && (
            <div className="rfloat col-sm-8 text-right filter-field">
              <DirectoryFilters
                nameSearch={this.state.nameSearch}
                clearFilters={this.state.clearFilters}
                handleFilters={this.handleFilters}
                contactType={searchContactType}
              />
            </div>
          )}
        </div>

        {contactsToMerge && contactsToMerge.length > 0 && (
          <div>
            {this.state.showEventEditor && (
              <ModalWithButtons
                buttons={[]}
                text=""
                content={
                  <EventSelector
                    contactType={
                      searchContactType === "shliach"
                        ? ContactType.shluchim
                        : ContactType.students
                    }
                    confirmCallback={this.confirmBulkSaveEvents}
                    cancelCallback={this.closeEventEditor}
                  />
                }
              />
            )}
            {this.state.confirmingSavingEvents && (
              <ModalWithButtons
                buttons={this.confirmAddEventsButtons()}
                text="Are you sure you want to add this Event?"
                content={this.messageComponent()}
              />
            )}
          </div>
        )}

        <div className="download-section">
          {searchContactType === "student" &&
            hasMergePermission &&
            !this.props.hideContactControls && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p style={{ fontWeight: 900 }}>Select students to merge</p>

                {contactsToMerge && contactsToMerge.length >= 2 && (
                  <a
                    className="btn btn-primary btn-md"
                    href={`/students/merge?${queryString.stringify({
                      id: contactsToMerge.map((c) => c.id),
                    })}`}
                  >
                    Start Merge
                  </a>
                )}
              </div>
            )}
          {!this.props.isAdvancedSearch && this.props.allowDownload && (
            <CsvGetAndDownload
              dataRetrieveFunction={this.requestDataForDownload}
              dataPath="results"
              fileName={this.getFileName()}
              columnDefinitions={this.getDownloadHeaders()}
            />
          )}
          {hasEditPermission &&
            !this.props.hideContactControls &&
            !this.props.isAdvancedSearch &&
            searchContactType !== "student" && (
              <Link to={this.getAddNewURL()} className="btn btn-primary btn-sm">
                <i className="icon icon-shluchim icon-white" /> Add New
              </Link>
            )}
        </div>

        <div className="content-window">
          {this.state.visibleResults.length === 0 && this.state.searched ? (
            <div className="text-center text-muted">
              <h2 className="">No Results</h2>
            </div>
          ) : (
            <table className="table table-custom table-custom-responsive">
              <thead>{this.props.renderTableHeader()}</thead>
              <tbody>
                {this.state.visibleResults.map(this.props.renderResult)}
              </tbody>
            </table>
          )}
        </div>
        <Paginator
          totalResults={this.state.totalResults}
          currentPage={this.state.selectedPage}
          handleOnChange={this.onChangePage}
        />
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

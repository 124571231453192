import React from "react";
import DatePicker from "../../../shared/DateTimePicker";
import PhoneInput from "../../../shared/PhoneInput";
import Toggle from "../../../shared/Toggle";
import ValidatedInput from "../../../shared/ValidatedInput";
import { removeTimezoneFormatFromDate, validateEmail } from "../../../../lib";
import moment from "moment";

export default class PersonalInfo extends React.PureComponent {
  // use UTC date when estimating dob off of age because the server calculates age based on UTC time
  getUtcNow = () => moment().add(new Date().getTimezoneOffset(), "minutes");

  getEstimatedDobFromAge = (age) => {
    if (age) {
      const estimatedDobYear = this.getUtcNow().subtract(age, "years").year();
      return moment({ year: estimatedDobYear, month: 0, day: 1 });
    }
    return "";
  };

  getAgeFromEstimatedDob = (dob) =>
    dob ? this.getUtcNow().diff(moment(dob), "years") : "";

  onBlurName = (fieldName, enteredName) => {
    const nameParts = enteredName.split(" ").filter((n) => n);
    const capitalizedName = nameParts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
    if (capitalizedName !== enteredName) {
      this.props.onChangeProfile(fieldName, capitalizedName);
    }
  };

  render() {
    const {
      countries,
      getProfileFieldLabel,
      initialProfile: {
        cell: initialCell,
        isDobEstimated: initialIsDobEstimated,
        person: { dob: initialDob },
      } = { person: {} },
      isProfileFieldRequired,
      onChangeProfile,
      onChangeProfileEvt,
      profile: {
        address,
        cell,
        cellCountryID,
        email,
        isArchived,
        isDobEstimated,
        isJewish,
        cannotRemoveDobAndCell,
        person: {
          dob = "",
          firstName = "",
          gender = "",
          hebrewName = "",
          lastName = "",
          // profileImageURL = "",
        } = {},
      } = { person: {} },
      profileValidationErrors,
      showFormValidation,
      updateProfileValidation,
    } = this.props;

    return (
      <div className="student-profile-form-section">
        <p className="profile-section-title">Personal information</p>
        {/* we are removing the image upload for shluchim for now */}
        {/* <div className="mb-16">
          <ImageUpload
            name="person.profileImageURL"
            label={
              <p
                className={`flex mobile-block ${
                  showFormValidation &&
                  isProfileFieldRequired("person.profileImageURL") &&
                  !profileImageURL
                    ? "error"
                    : ""
                }`}
              >
                Upload Profile Image{" "}
                {isProfileFieldRequired("person.profileImageURL") ? (
                  <span className="ml-4 required-text">*</span>
                ) : (
                  <span className="ml-8 mobile-ml-0 mobile-mt-8 xs-text accent-text flex flex-align-center">
                    (Optional)
                  </span>
                )}
              </p>
            }
            uploadedLabel="Change Profile Image"
            onChange={onChangeProfile}
            imageName="Profile Image"
            imageType="profile"
            imageURL={profileImageURL}
          />
        </div> */}
        <div className="student-profile-form-grid">
          <ValidatedInput
            className="input-container"
            label={getProfileFieldLabel("First Name", "person.firstName")}
            name="person.firstName"
            onBlur={this.onBlurName}
            onChange={onChangeProfileEvt}
            required={isProfileFieldRequired("person.firstName")}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            validate={(fn) => !fn || fn.length > 1}
            validationMessage="Please enter a full first name"
            value={firstName}
          />
          <ValidatedInput
            className="input-container"
            label={getProfileFieldLabel("Last Name", "person.lastName")}
            name="person.lastName"
            onBlur={this.onBlurName}
            onChange={onChangeProfileEvt}
            required={isProfileFieldRequired("person.lastName")}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            validate={(fn) => !fn || fn.length > 1}
            validationMessage="Please enter a full last name"
            value={lastName}
          />
          <ValidatedInput
            className="input-container"
            label={getProfileFieldLabel("Hebrew Name", "person.hebrewName")}
            name="person.hebrewName"
            onBlur={this.onBlurName}
            onChange={onChangeProfileEvt}
            required={isProfileFieldRequired("person.hebrewName")}
            showRequired={showFormValidation}
            value={hebrewName}
          />
          <div className="tooltip-container">
            {isDobEstimated ? (
              <ValidatedInput
                className="input-container"
                input={<input min="0" />}
                label={getProfileFieldLabel("Age", "person.dob")}
                name="person.dob"
                onChange={(evt) =>
                  onChangeProfile(
                    evt.target.name,
                    this.getEstimatedDobFromAge(evt.target.value)
                  )
                }
                required={isProfileFieldRequired("person.dob")}
                showRequired={showFormValidation}
                type="number"
                value={this.getAgeFromEstimatedDob(dob)}
              />
            ) : (
              <ValidatedInput
                className="input-container"
                input={
                  <DatePicker
                    maxDate={new Date()}
                    scrollableYearDropdown={true}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    yearDropdownItemNumber={40}
                  />
                }
                label={getProfileFieldLabel("Birthday", "person.dob")}
                name="person.dob"
                onChange={(name, value) =>
                  onChangeProfile(name, removeTimezoneFormatFromDate(value))
                }
                required={isProfileFieldRequired("person.dob")}
                showRequired={showFormValidation}
                value={dob}
              />
            )}
            {/* only allow to input age (which generates estimated dob) if dob was initially estimated or was not initially provided */}
            {(initialIsDobEstimated || !initialDob) && (
              <p className="xs-text accent-text text-right mt-4">
                {!isDobEstimated && "Don't know your student's Birthday? "}
                <span
                  className="link-text"
                  onClick={() => {
                    onChangeProfile("isDobEstimated", !isDobEstimated, {
                      "person.dob": "",
                    });
                  }}
                >
                  Enter {isDobEstimated ? "Birthday" : "Age"} instead
                </span>
                {isDobEstimated && " (preferred)"}
              </p>
            )}
            {isDobEstimated && (
              <p className="xs-text accent-text text-right mt-4">
                Age will be updated annually on Jan 1st GMT
              </p>
            )}
            {!!initialDob && cannotRemoveDobAndCell && (
              <span
                className="tooltip"
                style={{
                  zIndex: 1,
                  left: "0px",
                  right: "-24px",
                  whiteSpace: "normal",
                }}
              >
                {isDobEstimated ? "Age" : "Birthday"} may be replaced but not
                removed because this student has been submitted for a
                Lamplighters grant.
              </span>
            )}
          </div>
          <ValidatedInput
            className="input-container"
            label={getProfileFieldLabel("Email", "email")}
            name="email"
            onChange={onChangeProfileEvt}
            required={isProfileFieldRequired("email")}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            validate={(email) => {
              const isValid = validateEmail(email);
              updateProfileValidation("email", isValid);
              return isValid;
            }}
            validationMessage="Invalid email address"
            value={email}
          />
          <div
            className={`input-container tooltip-container ${
              profileValidationErrors.indexOf("cell") >= 0 ||
              (showFormValidation && isProfileFieldRequired("cell") && !cell)
                ? "error"
                : ""
            }`}
          >
            <label>{getProfileFieldLabel("Phone", "cell")}</label>
            <PhoneInput
              name="cell"
              countries={countries || []}
              countryId={cellCountryID}
              defaultCountryName={address && address.country} //fallback on country associated with student's address
              onChange={(cell, cellCountryID) => {
                onChangeProfile("cell", cell);
                onChangeProfile("cellCountryID", cellCountryID);
              }}
              error={profileValidationErrors.indexOf("cell") >= 0}
              validate={(isValid) => updateProfileValidation("cell", isValid)}
              value={cell}
            />
            {!!initialCell && cannotRemoveDobAndCell && (
              <span
                className="tooltip"
                style={{ zIndex: 1, whiteSpace: "normal" }}
              >
                Cellphone number may be replaced but not removed because this
                student has been submitted for a Lamplighters grant.
              </span>
            )}
          </div>
          <ValidatedInput
            input={
              <Toggle
                allowNoOption={true}
                className="toggle-container"
                noOptionValue="Unknown"
                options={[
                  {
                    value: "Male",
                    display: "Male",
                  },
                  {
                    value: "Female",
                    display: "Female",
                  },
                ]}
              />
            }
            label={getProfileFieldLabel("Gender", "person.gender")}
            labelClassName="uppercase-text xs-text accent-text mb-4 block"
            name="person.gender"
            onChange={onChangeProfile}
            required={isProfileFieldRequired("person.gender")}
            showRequired={showFormValidation}
            value={gender === "Unknown" ? "" : gender}
          />
          <ValidatedInput
            input={
              <Toggle
                allowNoOption={true}
                className="toggle-container"
                options={[
                  {
                    value: true,
                    display: "Yes",
                  },
                  {
                    value: false,
                    display: "No, but interested",
                  },
                ]}
              />
            }
            label={getProfileFieldLabel("Jewish", "isJewish")}
            labelClassName="uppercase-text xs-text accent-text mb-4 block"
            name="isJewish"
            onChange={onChangeProfile}
            required={isProfileFieldRequired("isJewish")}
            showRequired={
              showFormValidation && isJewish !== true && isJewish !== false
            }
            value={isJewish}
          />
          <ValidatedInput
            input={
              <Toggle
                className="toggle-container"
                options={[
                  {
                    value: true,
                    display: "Yes",
                  },
                  {
                    value: false,
                    display: "No",
                  },
                ]}
              />
            }
            label={getProfileFieldLabel("Archived", "isArchived")}
            labelClassName="uppercase-text xs-text accent-text mb-4 block"
            name="isArchived"
            onChange={onChangeProfile}
            required={isProfileFieldRequired("isArchived")}
            showRequired={showFormValidation}
            value={isArchived}
          />
        </div>
      </div>
    );
  }
}

import React from "react";

import { FieldArray } from "formik";
import CustomSelect from "../../Form/CustomSelect";
import FormInputUpdate from "../../Form/FormInputUpdate";
import PhoneInput from "../../../../shared/PhoneInput";
export default class ContactInfo extends React.PureComponent {
  state = {
    contactInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        contactInfoShowing: false,
      });
    }
  }

  contactMethodsTouched = (touched) => {
    return (
      touched &&
      touched.person &&
      touched.person.contactMethods &&
      touched.person.contactMethods
        .map((cm) => (cm && cm.value ? cm.value : null))
        .indexOf(true) >= 0
    );
  };

  render() {
    const {
      errors,
      handleChange,
      setFieldError,
      setFieldValue,
      require,
      touched,
      validateContactMethod,
      values,
      readOnly,
      shliachType,
      sys: { countries, shluchimContactMethodTypes },
    } = this.props;

    return (
      <div
        className={
          this.state.contactInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <FieldArray name="person.contactMethods">
          {(arrayHelpers) => {
            const contactMethodsTouched = this.contactMethodsTouched(touched);
            return (
              <React.Fragment>
                <div className="flex mobile-flex-justify-space flex-align-center mt-48 mb-8">
                  <p
                    className="accent-text-dark medium-text fw-700"
                    onClick={() => {
                      this.setState({
                        contactInfoShowing: !this.state.contactInfoShowing,
                      });
                    }}
                  >
                    {shliachType} Contact Information
                  </p>
                  {readOnly ? null : (
                    <i
                      className={`material-icons link-text profile-add-icon ml-16 ${
                        !this.state.contactInfoShowing ? "hidden" : ""
                      }`}
                      onClick={() => {
                        this.contactMethodsTouched();
                        arrayHelpers.unshift({
                          isPrimary: false,
                          type: "",
                          value: "",
                        });
                      }}
                    >
                      add_circle
                    </i>
                  )}
                </div>
                <div className="profile-form-contact-info">
                  <p className="small-text accent-text mb-16">
                    <span
                      className={
                        errors.incompleteContactMethods && contactMethodsTouched
                          ? "contact-error-message"
                          : ""
                      }
                    >
                      One email is required. Select one preferred and one
                      primary contact method.
                    </span>
                    <span style={{ display: "block" }}>
                      Please ensure that one email is a google address so that
                      it can be used to log in.
                    </span>
                  </p>
                  {values.person &&
                    values.person.contactMethods &&
                    values.person.contactMethods.map((contactMethod, index) => (
                      <div
                        key={index}
                        className="flex flex-align-center flex-justify-space mb-16 hover-delete-container mobile-card"
                      >
                        <div className="relative">
                          <FormInputUpdate
                            name={`person.contactMethods[${index}].type`}
                            validate={require}
                            errors={errors}
                            touched={touched}
                            className="custom-input mr-12 form-required"
                            style={{ flex: "2" }}
                            readOnly={readOnly}
                          >
                            {({ field }) => (
                              <CustomSelect
                                {...field}
                                errors={errors}
                                options={
                                  shluchimContactMethodTypes &&
                                  shluchimContactMethodTypes.map((t) => ({
                                    key: t.enumValue,
                                    value: t.enumValue,
                                    label: t.displayValue,
                                  }))
                                }
                                readOnly={readOnly}
                                placeholder="Select Type..."
                                setFieldValue={setFieldValue}
                                touched={touched}
                              />
                            )}
                          </FormInputUpdate>
                        </div>
                        <div className="relative">
                          {contactMethod.type === "" ||
                          contactMethod.type === "Email" ? (
                            <FormInputUpdate
                              type="Email"
                              placeholder="Enter Value"
                              name={`person.contactMethods[${index}].value`}
                              errors={errors}
                              touched={touched}
                              className="custom-input mr-24 form-required"
                              style={{ flex: "3" }}
                              readOnly={readOnly}
                              validate={(value) =>
                                validateContactMethod(
                                  value,
                                  contactMethod,
                                  touched
                                )
                              }
                            />
                          ) : (
                            <FormInputUpdate
                              name={`person.contactMethods[${index}].value`}
                              errors={errors}
                              touched={touched}
                              className="custom-input mr-12"
                              style={{ flex: "2" }}
                              readOnly={readOnly}
                            >
                              {({ field }) =>
                                // TODO: figure out a cleaner way to fix this, phoneInput is mounting on student outreach tab and causing an error
                                this.props.tab === "general" ? (
                                  <PhoneInput
                                    {...field}
                                    className="phone-input"
                                    countries={countries || []}
                                    countryFieldName={`person.contactMethods[${index}].phoneCountryID`}
                                    countryId={
                                      values.person.contactMethods[index]
                                        .phoneCountryID
                                    }
                                    defaultCountryName={
                                      values &&
                                      values.family &&
                                      values.family.address &&
                                      values.family.address.country
                                    }
                                    disabled={readOnly}
                                    formikErrors={errors}
                                    formikTouched={touched}
                                    value={
                                      values.person.contactMethods[index].value
                                    }
                                    setFieldError={setFieldError}
                                    setFieldValue={setFieldValue}
                                  />
                                ) : null
                              }
                            </FormInputUpdate>
                          )}
                        </div>
                        <div
                          className="relative flex flex-align-center"
                          style={{ minHeight: "32px" }}
                        >
                          <FormInputUpdate
                            name={`person.contactMethods[${index}].isPreferred`}
                            errors={errors}
                            touched={touched}
                            readOnly={readOnly}
                          >
                            {({ field }) => (
                              <div className="radio-button-container">
                                <div className="flex">
                                  <input
                                    type="checkbox"
                                    id={`contact-method-${index}`}
                                    {...field}
                                    value="isPreferred"
                                    checked={contactMethod.isPreferred}
                                    disabled={readOnly}
                                    onChange={(e) => {
                                      const currentPrimaryCMIndex =
                                        values.person.contactMethods
                                          .map((cm) => cm.isPreferred)
                                          .indexOf(true);
                                      handleChange({
                                        target: {
                                          name: `person.contactMethods[${currentPrimaryCMIndex}].isPreferred`,
                                          value: false,
                                        },
                                      });
                                      field.onChange(e);
                                    }}
                                  />

                                  <label htmlFor={`contact-method-${index}`} />
                                  <span>preferred method of contact</span>
                                </div>
                              </div>
                            )}
                          </FormInputUpdate>
                        </div>
                        <div
                          className="relative flex flex-align-center"
                          style={{ minHeight: "32px" }}
                        >
                          <FormInputUpdate
                            name={`person.contactMethods[${index}].isPrimary`}
                            errors={errors}
                            touched={touched}
                            readOnly={readOnly}
                          >
                            {({ field }) =>
                              contactMethod.type === "Email" ||
                              contactMethod.type === "MobileNumber" ? (
                                <div className="radio-button-container">
                                  <div className="flex">
                                    <input
                                      type="checkbox"
                                      id={`contact-method-primary-${index}`}
                                      {...field}
                                      value="isPrimary"
                                      checked={contactMethod.isPrimary}
                                      disabled={readOnly}
                                      onChange={(e) => {
                                        let primaryEmail =
                                          values.person.contactMethods
                                            .map(
                                              (cm) =>
                                                cm.isPrimary &&
                                                cm.type === "Email"
                                            )
                                            .indexOf(true);
                                        let primaryCell =
                                          values.person.contactMethods
                                            .map(
                                              (cm) =>
                                                cm.isPrimary &&
                                                cm.type === "MobileNumber"
                                            )
                                            .indexOf(true);

                                        if (contactMethod.type === "Email")
                                          handleChange({
                                            target: {
                                              name: `person.contactMethods[${primaryEmail}].isPrimary`,
                                              value: false,
                                            },
                                          });
                                        if (
                                          contactMethod.type === "MobileNumber"
                                        ) {
                                          handleChange({
                                            target: {
                                              name: `person.contactMethods[${primaryCell}].isPrimary`,
                                              value: false,
                                            },
                                          });
                                        }
                                        field.onChange(e);
                                      }}
                                    />
                                    <label
                                      htmlFor={`contact-method-primary-${index}`}
                                    />
                                    <span>
                                      primary{" "}
                                      {contactMethod.type === "Email"
                                        ? "email"
                                        : contactMethod.type === "MobileNumber"
                                        ? "cell"
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              ) : null
                            }
                          </FormInputUpdate>
                        </div>
                        <div className="flex flex-align-center">
                          {!(
                            (contactMethod.type === "Email" ||
                              contactMethod.type === "MobileNumber") &&
                            values.person.contactMethods.filter(
                              (cm) => cm.type === contactMethod.type
                            ).length === 1
                          ) &&
                            !readOnly && (
                              <i
                                className="material-icons link-text hover-delete"
                                style={{ fontSize: "16px" }}
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                delete
                              </i>
                            )}
                        </div>
                      </div>
                    ))}
                </div>
              </React.Fragment>
            );
          }}
        </FieldArray>
      </div>
    );
  }
}

import React from "react";
import { formatFullName } from "../../../lib";

export default function OtherPeopleTableRow({
  onRemoveEmail,
  onRemoveCell,
  person,
  readOnly,
}) {
  const { cell, classificationDisplay, email, firstName, lastName, personID } =
    person;
  return (
    <div className="other-people-table-row">
      <p>{personID}</p>
      <p>{formatFullName(firstName, lastName)}</p>
      <p>{classificationDisplay}</p>
      <p
        className="flex flex-align-center flex-justify-space"
        style={{ maxWidth: "300px" }}
      >
        <span>{email}</span>
        {!readOnly && email && (
          <i
            className="material-icons link-text-secondary ml-4"
            onClick={onRemoveEmail}
            style={{ fontSize: "16px" }}
          >
            delete
          </i>
        )}
      </p>
      <p
        className="flex flex-align-center flex-justify-space"
        style={{ maxWidth: "180px" }}
      >
        <span>{cell}</span>
        {!readOnly && cell && (
          <i
            className="material-icons link-text-secondary ml-4"
            onClick={onRemoveCell}
            style={{ fontSize: "16px" }}
          >
            delete
          </i>
        )}
      </p>
    </div>
  );
}

import LogRocket from "logrocket";
import queryString from "query-string";

const {
  REACT_APP_API_URL = "",
  REACT_APP_HOST_ENV = "development",
  REACT_APP_LEGACY_API = "",
  REACT_APP_LOGROCKET_APPID = "",
} = process.env;
const __DEV__ = REACT_APP_HOST_ENV === "development";
const __AUTOMATED__ = navigator.webdriver;

export function logRocketInit() {
  if (!__DEV__ && !__AUTOMATED__) {
    LogRocket.init(REACT_APP_LOGROCKET_APPID, {
      release: `admin-${REACT_APP_HOST_ENV}`,
      network: {
        requestSanitizer: requestResponseSanitizer,
        responseSanitizer: requestResponseSanitizer,
      },
    });
  }
}

export function logRocketIdentifyUser(id: string, name: string) {
  if (!__DEV__ && !__AUTOMATED__) {
    // we send id (user's personId) as the uuid here because we don't have the user email
    LogRocket.identify(id, {
      name,
      portal: "admin_portal",
    });
  }
}

// #region Network data sanitization

interface LRNetworkRequestResponse {
  reqId: string;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
  // request
  url: string;
  referrer?: string;
  mode?: string;
  credentials?: string;
  // response
  status?: number;
}

const sanitizationPlaceholder = "__REDACTED__";

function requestResponseSanitizer(requestResponse: LRNetworkRequestResponse) {
  // sanitize only network requests/responses to/from our APIs
  if (
    !requestResponse.url?.startsWith(REACT_APP_API_URL) &&
    !requestResponse.url?.startsWith(REACT_APP_LEGACY_API)
  ) {
    return requestResponse;
  }

  // remove JWT authorization header (for authorized requests)
  if (requestResponse.headers.Authorization) {
    requestResponse.headers.Authorization = sanitizationPlaceholder;
  }

  if (requestResponse.body) {
    networkDataHandler(requestResponse);
  }

  return requestResponse;
}

function networkDataHandler(requestResponse: LRNetworkRequestResponse) {
  const { body, headers } = requestResponse;

  if (!body || body === "null") {
    return requestResponse;
  }

  const isUrlEncodedRequest =
    headers?.["Content-Type"]?.includes("form-urlencoded");
  const converter = isUrlEncodedRequest ? queryString : JSON;

  try {
    const parsedBody = converter.parse(body);
    if (parsedBody) {
      sanitizeData(parsedBody);

      const sanitizedBody = converter.stringify(parsedBody);
      // if any data sanitization was applied, replace the requestResponse.body with the sanitizedBody
      if (sanitizedBody.includes(sanitizationPlaceholder)) {
        console.log("sanitized!");
        requestResponse.body = sanitizedBody;
      }
    }
  } catch (e) {
    console.error(e);
  }

  return requestResponse;
}

const sensitiveDataKeys = [
  "password",
  "messagingAuthToken",
  "secretKey",
  "officialIdNumber",
  "knownTravelerNumber",
];

function sanitizeData(data: any) {
  if (data instanceof Array) {
    data.forEach(sanitizeData);
  } else {
    Object.keys(data).forEach((key) => {
      const keyValue = data[key];
      if (!keyValue) return;

      if (
        sensitiveDataKeys.some((k) => k.toLowerCase() === key.toLowerCase())
      ) {
        data[key] = sanitizationPlaceholder;
      } else if (keyValue instanceof Object) {
        sanitizeData(keyValue);
      }
    });
  }
}

// #endregion

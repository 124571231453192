import React, { useState } from "react";
import moment from "moment";
import FilePreviewModal from "../../../../../common/FilePreviewModal";
import { formatFullName } from "../../../../../../lib";

function RegistrationPersonal(props) {
  const {
    isLivingLinksTrip,
    isPassportIdRequired,
    registration: {
      student: { legalFirstName, legalMiddleName, legalLastName },
      travelApplication,
    },
  } = props;

  const {
    additionalInfoNote,
    hasPriorTripExperience,
    jewishCampusInvolvementDescription,
    knownTravelerNumber,
    officialIdExpirationDate,
    officialIdNumber,
    officialIdDocumentUrl,
    otherReferralDescription,
    priorTripExperienceDescription,
    referralSourcesDisplay,
    tripReason,
  } = travelApplication || {};

  const [showOfficialIdDocumentPreview, setShowOfficialIdDocumentPreview] =
    useState(false);

  const officialIdDescription = isPassportIdRequired ? "Passport" : "ID";

  return (
    <div className="trip-student-detail-tab personal-tab">
      <div className="xl-text fw-700">Personal</div>
      {isLivingLinksTrip && (
        <>
          <div className="student-detail-section">
            <div className="medium-text fw-700 mb-8">
              In two paragraphs or more describe why you want to join this trip
              and what you hope to gain from it.
            </div>
            <div>{tripReason}</div>
          </div>
          <div className="student-detail-section">
            <div className="medium-text fw-700 mb-8">
              How did you hear about the trip?
            </div>
            <div>
              {referralSourcesDisplay.join(", ")}
              {otherReferralDescription && (
                <div className="mt-8">{otherReferralDescription}</div>
              )}
            </div>
          </div>
          <div className="student-detail-section">
            <div className="medium-text fw-700 mb-8">
              Have you previously participated in a trip to Poland?
            </div>
            <div>
              {hasPriorTripExperience ? "Yes" : "No"}
              {priorTripExperienceDescription && (
                <div className="mt-8">{priorTripExperienceDescription}</div>
              )}
            </div>
          </div>
          <div className="student-detail-section">
            <div className="medium-text fw-700 mb-8">
              Please tell us about your current Jewish campus involvement.
            </div>
            <div>{jewishCampusInvolvementDescription}</div>
          </div>
        </>
      )}
      <div className="student-detail-section">
        <div className="medium-text fw-700 mb-8">
          {officialIdDescription} Information
        </div>
        <div className="double-grid-container">
          <div className="two-column-grid">
            <p className="fw-700">Legal name</p>
            <p>
              {formatFullName(legalFirstName, legalLastName, legalMiddleName)}
            </p>
            <p className="fw-700">{officialIdDescription} number</p>
            <p data-private>{officialIdNumber}</p>
            <p className="fw-700">{officialIdDescription} expiration</p>
            <p>{moment(officialIdExpirationDate).format("MM/DD/YYYY")}</p>
          </div>
          <div className="two-column-grid">
            <p className="fw-700">Known traveler number</p>
            <p data-private>{knownTravelerNumber || "–"}</p>
            <p className="fw-700">Copy of {officialIdDescription}</p>
            <p
              className="flex flex-align-center link-text"
              onClick={() => setShowOfficialIdDocumentPreview(true)}
            >
              <i className="material-icons mr-8">description</i>
              {officialIdDescription}
            </p>
            <FilePreviewModal
              close={() => setShowOfficialIdDocumentPreview(false)}
              isSecure={true}
              show={showOfficialIdDocumentPreview}
              title={`Copy of ${officialIdDescription}`}
              url={officialIdDocumentUrl}
            />
            <p />
          </div>
        </div>
      </div>
      <div className="student-detail-section">
        <div className="medium-text fw-700 mb-8">
          Is there anything else we should know about you or your application?
        </div>
        <div>{additionalInfoNote || "–"}</div>
      </div>
    </div>
  );
}

export default React.memo(RegistrationPersonal);

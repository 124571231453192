import React from "react";
import CopyToClipboardTextbox from "../../../../shared/CopyToClipboardTextbox";
import Modal from "react-modal";
import PhoneInput from "../../../../shared/PhoneInput";
import Select from "../../../../shared/Select";
import SelectSearch from "react-select";
import Loader from "../../../../common/Loader";
import SystemApi from "../../../../../services/resources/SystemApi";
import {
  formatAddressDisplay,
  formatFullName,
  getFormattedValuesForForm,
  removeEmptyFromObj,
  validateEmail,
} from "../../../../../lib/utils";
import RaffleOrderInfo from "./RaffleOrderInfo";
import moment from "moment";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";
import queryString from "query-string";

import RafflesApi from "../../../../../services/resources/RafflesApi";
import { ApiCallErrorMessageHandler } from "../../../../../lib/coc-common-scripts";
import axios from "axios";
import RaffleOrderAddress from "./RaffleOrderAddress";
import { RafflePaymentTypes } from "../../../RaffleConsts";

export default class RaffleOrderDetailsModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      editMode: false,

      initialOrder: null,
      order: null,
      orderError: null,
      orderLoading: false,

      orderValidationErrors: [],
      submitAttempted: false,

      referrers: [],
      referrersErrorMessage: "",
      referrersLoading: true,

      sellers: [],
      sellersErrorMessage: "",
      sellersLoading: true,

      cashTypes: [],
    };
    state.initialState = _cloneDeep(state);

    this.state = state;
  }

  apiSignal = axios.CancelToken.source();

  loadOrder() {
    this.setState({ orderError: null, orderLoading: true });
    this.props
      .getOrderDetails()
      .then(({ orderDetails, error, errorMessage }) => {
        if (error || !orderDetails) {
          this.setState({
            orderError:
              errorMessage ||
              "Something went wrong and your order could not be updated.  Please try again.",
            orderLoading: false,
          });
        } else {
          const order = getFormattedValuesForForm(orderDetails);
          this.setState({ order, orderLoading: false, editMode: false });
          this.getSellersList(order.sellerEnrollmentID);
        }
      });
  }

  componentDidMount() {
    this.loadOrder();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onChangeOrder = (name, value) => {
    let order = _cloneDeep(this.state.order);

    _set(order, name, value);
    this.setState({ order }, () => {
      if (name === "sellerEnrollmentID") {
        //refresh referrers list, clear out referrerID
        this.getReferrersList(value);
        this.onChangeOrder("referrerID", "");
      }
    });
  };

  onChangeEventOrder = (event) => {
    this.onChangeOrder(event.target.name, event.target.value);
  };

  onClose = () => {
    this.setState(this.state.initialState, this.props.close);
  };

  onSaveOrderChanges = async () => {
    const { initialOrder, order } = this.state;
    const { updateOrderDetails } = this.props;

    if (_isEqual(order, initialOrder)) {
      return;
    }

    this.setState({ orderError: null, submitAttempted: true });

    //validate donor changes:
    if (!this.validateOrderInfo()) {
      return;
    }

    this.setState({ orderLoading: true });

    //clear cash type if pay method is no longer cash
    if (order.paymentType === "Check") {
      order.cashTypeID = undefined;
    }

    const orderDetailsForUpdate = _cloneDeep(order);
    removeEmptyFromObj(orderDetailsForUpdate);

    const { error, errorMessage } = await updateOrderDetails(
      orderDetailsForUpdate
    );

    if (error) {
      this.setState({
        orderError:
          errorMessage ||
          "Something went wrong and your order could not be updated.  Please try again.",
        orderLoading: false,
      });
    } else {
      this.loadOrder();
    }
  };

  onToggleEditMode = async () => {
    const { order, editMode, referrers, sellers, cashTypes } = this.state;

    if (!editMode) {
      this.setState({
        initialOrder: _cloneDeep(order),
        orderLoading: false,
      });

      const {
        order: { sellerEnrollmentID },
      } = this.state;
      if (!sellers.length) {
        this.getSellersList(sellerEnrollmentID);
      }
      if (!referrers.length) {
        this.getReferrersList(sellerEnrollmentID);
      }
      if (!cashTypes.length) {
        this.getCashTypesList();
      }

      this.setState({
        editMode: true,
      });
    } else {
      this.setState({
        ...this.state.initialState,
        order: this.state.initialOrder,
      });
    }
  };

  updateOrderValidationErrors = (name, isValid) => {
    const { orderValidationErrors } = this.state;
    this.setState({
      orderValidationErrors: isValid
        ? orderValidationErrors.filter((err) => err !== name)
        : [...orderValidationErrors, name],
    });
  };

  validateOrderInfo = () => {
    const {
      order: { email, firstName, lastName, orderDate },
      orderValidationErrors,
    } = this.state;

    return (
      !!email &&
      !!firstName &&
      !!lastName &&
      !!orderDate &&
      !orderValidationErrors.length
    );
  };

  getSellersList = (sellerEnrollmentId) => {
    this.setState(
      {
        sellersErrorMessage: "",
        sellersLoading: true,
      },
      () => {
        RafflesApi.getRaffleSellers(this.apiSignal.token, this.props.raffleId)
          .then((sellers) => {
            const currentSellerCurrencyCode = (
              sellers.find(
                (s) => s.sellerEnrollmentID === sellerEnrollmentId
              ) || {}
            ).currencyCode;
            this.setState({
              sellers: currentSellerCurrencyCode
                ? sellers.filter(
                    (s) => s.currencyCode === currentSellerCurrencyCode
                  )
                : sellers,
              sellersLoading: false,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                sellers: [],
                sellersErrorMessage: ApiCallErrorMessageHandler(err),
                sellersLoading: false,
              });
            }
          });
      }
    );
  };

  getReferrersList = (sellerEnrollmentId) => {
    this.setState(
      {
        referrersErrorMessage: "",
        referrersLoading: true,
      },
      () => {
        RafflesApi.getEnrollmentReferrers(
          this.apiSignal.token,
          sellerEnrollmentId
        )
          .then((referrers) => {
            this.setState({
              referrers,
              referrersLoading: false,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                referrers: [],
                referrersErrorMessage: ApiCallErrorMessageHandler(err),
                referrersLoading: false,
              });
            }
          });
      }
    );
  };

  getCashTypesList = () => {
    SystemApi.lists(["cashTypes"]).then((cashTypes) => {
      this.setState(cashTypes);
    });
  };

  render() {
    if (!this.state.order) return null;

    const {
      cociCcProcessingFeePercentage,
      location,
      order: {
        //only non-editable fields
        ccBrand,
        lastFour,
        orderNumber,
        providerID,
      },
      raffleManualTicketEntryDeadline,
      readOnlyAccess,
      show,
      systemCountries,
      systemRaffleDonorAssociations,
      startDate,
      endDate,
    } = this.props;

    const {
      editMode,
      cashTypes,
      orderError,
      orderLoading,
      orderValidationErrors = [],
      referrers,
      referrersErrorMessage,
      referrersLoading,
      sellers,
      sellersErrorMessage,
      sellersLoading,
      submitAttempted,
      order,
      initialOrder,
      order: {
        //editable fields
        firstName,
        lastName,
        phone,
        email,
        tribute,
        isPrivate,
        associationDisplay,
        association,
        paymentNotes,
        paymentType,
        source,
        referrerID,
        cashType,
        cashTypeID,
        sellerEnrollmentID,
        referrerName,
        address: { address1, address2, city, state, zip, country },
      },
    } = this.state;

    const allowEditSeller =
      editMode &&
      (paymentType === RafflePaymentTypes.CreditCard ||
        paymentType === RafflePaymentTypes.PayPal) &&
      moment(raffleManualTicketEntryDeadline).diff(moment(), "days") >= 2;

    const sellerOptions =
      sellers &&
      sellers.map((seller) => ({
        label: `${seller.chabadHouseName} ${
          seller.shliachLastName
            ? ` - ${formatFullName(
                seller.shliachFirstName,
                seller.shliachLastName
              )}`
            : ""
        }`,
        value: seller.sellerEnrollmentID,
      }));
    const stripeProviderID = providerID?.slice(3);

    return (
      <Modal isOpen={show} className="scrollable-modal-container">
        <div
          className={`modal-card card order-details-modal ${
            editMode ? "order-edit-modal" : ""
          }`}
        >
          <div className="flex flex-justify-space mb-24">
            <div>
              <div className="flex flex-align-center">
                <p className="xl-text fw-700">Order #{orderNumber}</p>
                <div className="mr-16 flex flex-align-center">
                  {!readOnlyAccess &&
                    (orderLoading ? (
                      <div className="ml-16">
                        <Loader />
                      </div>
                    ) : !editMode ? (
                      <i
                        className="material-icons link-text-secondary ml-16"
                        onClick={this.onToggleEditMode}
                      >
                        edit
                      </i>
                    ) : (
                      <div className="flex flex-align-center">
                        <i
                          className={`material-icons link-text-secondary large-text ml-16 ${
                            _isEqual(order, initialOrder) ? "disabled" : ""
                          }`}
                          onClick={this.onSaveOrderChanges}
                        >
                          check
                        </i>
                        <i
                          className="material-icons link-text-secondary large-text ml-4"
                          onClick={this.onToggleEditMode}
                        >
                          close
                        </i>
                      </div>
                    ))}
                  {orderError && (
                    <span className="error-text ml-8">{orderError}</span>
                  )}
                </div>
              </div>
              <CopyToClipboardTextbox
                className="raffle-order-link"
                id="order-url"
                label="Copy Order Link"
                text={`${window.location.origin}${
                  location.pathname
                }?${queryString.stringify({
                  ...location.query,
                  order: orderNumber,
                })}`}
              />
            </div>
            <i
              className="material-icons large-text link-text-secondary"
              onClick={this.onClose}
            >
              close
            </i>
          </div>
          <p className="medium-text">Donor Info</p>
          {editMode ? (
            <div className="new-order-modal-grid mb-24">
              <div className="flex flex-justify-space flex-align-center mr-8 relative">
                <label className="accent-text small-text required">
                  First Name
                </label>
                <input
                  type="text"
                  className={`custom-input ${
                    submitAttempted && !firstName ? "error" : ""
                  }`}
                  name="firstName"
                  required={true}
                  onChange={this.onChangeEventOrder}
                  value={order.firstName}
                />
              </div>
              <div className="flex flex-justify-space flex-align-center ml-8 relative">
                <label className="accent-text small-text required">
                  Last Name
                </label>
                <input
                  type="text"
                  className={`custom-input ${
                    submitAttempted && !lastName ? "error" : ""
                  }`}
                  name="lastName"
                  onChange={this.onChangeEventOrder}
                  value={lastName}
                />
              </div>
              <div className="flex flex-justify-space flex-align-center mr-8 relative mb-8">
                <label className="accent-text small-text">Phone</label>
                {/* Note: we do not save phoneCountry for raffle orders, we t/f default the country here to the country of the donor's address */}
                <PhoneInput
                  countries={systemCountries || []}
                  defaultCountryName={country}
                  error={orderValidationErrors.indexOf("phone") >= 0}
                  name="phone"
                  validate={(isValid) =>
                    this.updateOrderValidationErrors("phone", isValid)
                  }
                  onChange={(cell) => {
                    this.onChangeOrder("phone", cell);
                  }}
                  value={phone}
                  validateCountry={false}
                />
              </div>
              <div className="flex flex-justify-space flex-align-center ml-8 relative">
                <label className="accent-text small-text required">Email</label>
                <input
                  type="text"
                  className={`custom-input ${
                    (submitAttempted && !email) ||
                    orderValidationErrors.indexOf("email") >= 0
                      ? "error"
                      : ""
                  }`}
                  name="email"
                  onBlur={(event) => {
                    const isValidEmail =
                      !event.target.value || validateEmail(event.target.value);
                    this.updateOrderValidationErrors(
                      event.target.name,
                      isValidEmail
                    );
                  }}
                  onChange={this.onChangeEventOrder}
                  value={email}
                />
              </div>

              <div className="flex flex-justify-space flex-align-center mr-8 relative">
                <label className="accent-text small-text">Tribute</label>
                <textarea
                  className={"custom-input"}
                  style={{ height: 48 }}
                  name="tribute"
                  onChange={this.onChangeEventOrder}
                  value={tribute}
                  rows={3}
                />
              </div>
              <div className="flex flex-justify-space flex-align-center ml-8">
                <label className="accent-text small-text">Association</label>
                <Select
                  className="custom-input full-width relative"
                  clearable={true}
                  name="association"
                  onChange={this.onChangeOrder}
                  options={systemRaffleDonorAssociations.map((assoc) => ({
                    display: assoc.displayValue,
                    value: assoc.enumValue,
                  }))}
                  placeholder="Select association"
                  value={association}
                />
              </div>

              {referrers.length > 0 && (
                <div className="flex flex-justify-space flex-align-center mr-8">
                  <label className="accent-text small-text">Team</label>
                  <Select
                    className="custom-input full-width relative"
                    clearable={true}
                    disabled={referrersErrorMessage || referrersLoading}
                    name="referrerID"
                    onChange={this.onChangeOrder}
                    options={referrers.map((referrer) => ({
                      display: referrer.name,
                      value: referrer.id,
                    }))}
                    placeholder={
                      referrersErrorMessage
                        ? "Error loading teams"
                        : referrersLoading
                        ? "Loading teams..."
                        : ""
                    }
                    value={referrerID}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="mb-24">
              <div className="flex flex-align-center mb-8 mr-24 relative">
                <p className="accent-text small-text fw-700">Name</p>
                <p className="small-text">
                  {formatFullName(firstName, lastName)}
                </p>
              </div>
              <div className="flex flex-align-center mb-8 relative">
                <p className="accent-text small-text  fw-700">Address</p>
                <p className="small-text" style={{ whiteSpace: "pre-wrap" }}>
                  {formatAddressDisplay(
                    address1,
                    address2,
                    city,
                    state,
                    zip,
                    country
                  )}
                </p>
              </div>
              <div className="flex flex-align-center mb-8 mr-24 relative">
                <p className="accent-text small-text  fw-700">Email</p>
                <p className="small-text">{email}</p>
              </div>
              <div className="flex flex-align-center mb-8 relative">
                <p className="accent-text small-text  fw-700">Phone</p>
                <p className="small-text">{phone}</p>
              </div>
              {referrerName && (
                <React.Fragment>
                  <div className="flex flex-align-center mb-8 mr-24 relative">
                    <p className="accent-text small-text  fw-700">Team</p>
                    <p className="small-text">{referrerName}</p>
                  </div>
                  <div />
                </React.Fragment>
              )}
              {associationDisplay !== "None" && (
                <React.Fragment>
                  <div className="flex flex-align-center mb-8 mr-24 relative">
                    <p className="accent-text small-text fw-700">Association</p>
                    <p className="small-text">{associationDisplay}</p>
                  </div>
                  <div />
                </React.Fragment>
              )}
              {tribute && (
                <React.Fragment>
                  <div className="flex flex-align-center mb-8 relative">
                    <p className="accent-text small-text fw-700">Tribute</p>
                    <p className="small-text">{tribute}</p>
                  </div>
                  <div />
                </React.Fragment>
              )}
            </div>
          )}
          <div>
            <RaffleOrderInfo
              editMode={editMode}
              order={order}
              onChangeOrder={this.onChangeOrder}
              startDate={startDate}
              endDate={endDate}
              cociCcProcessingFeePercentage={cociCcProcessingFeePercentage}
              updateOrderValidationErrors={this.updateOrderValidationErrors}
              orderValidationErrors={orderValidationErrors}
              {...this.props.order}
            />
            {paymentType === RafflePaymentTypes.CreditCard && (
              <>
                <div className="flex flex-align-center mb-12 mr-24 relative">
                  <label className="accent-text small-text">
                    Payment Method
                  </label>

                  <p className="small-text">
                    {`${ccBrand || "CC"} *${lastFour}`}
                  </p>
                </div>
                <div className="flex flex-align-center mb-8 relative">
                  <label className="accent-text small-text">
                    Stripe Purchase ID
                  </label>
                  <p className="small-text">{stripeProviderID}</p>
                </div>
              </>
            )}
            {paymentType === RafflePaymentTypes.Cash &&
              (editMode ? (
                <div className="flex flex-align-center mb-8 mr-24 relative">
                  <label className="accent-text small-text mr-24">
                    Payment Method
                  </label>
                  <Select
                    className="custom-input input-size relative"
                    clearable={false}
                    name="cashTypeID"
                    onChange={this.onChangeOrder}
                    options={cashTypes.map((cashType) => {
                      return {
                        display: cashType.name,
                        value: cashType.id,
                      };
                    })}
                    tabIndex={140}
                    value={cashTypeID}
                  />
                </div>
              ) : (
                <div className="flex flex-align-center mb-8 mr-24 relative">
                  <label className="accent-text small-text">
                    Payment Method
                  </label>
                  <p className="small-text">{cashType}</p>
                </div>
              ))}
            {editMode ? (
              <div className="flex flex-align-center mb-8 mr-24 relative">
                <label className="accent-text small-text">Payment Notes</label>
                <input
                  type="text"
                  className="custom-input full-width"
                  name="paymentNotes"
                  onChange={this.onChangeEventOrder}
                  value={paymentNotes}
                />
              </div>
            ) : (
              paymentNotes && (
                <React.Fragment>
                  <div className="flex flex-align-center mb-8 relative">
                    <p className="accent-text small-text fw-700">
                      Payment Notes
                    </p>
                    <p className="small-text">{paymentNotes}</p>
                  </div>
                  <div />
                </React.Fragment>
              )
            )}
            <div className="flex flex-align-center mb-8 relative">
              <label className="accent-text small-text">Source</label>
              <p className="small-text">{source}</p>
            </div>
            {editMode && allowEditSeller ? (
              <div className="flex flex-align-center relative mb-12">
                <label className="accent-text small-text ">Seller</label>
                <SelectSearch
                  className="custom-select"
                  clearable={false}
                  disabled={sellersErrorMessage || sellersLoading}
                  onChange={({ value }) =>
                    this.onChangeOrder("sellerEnrollmentID", value)
                  }
                  options={sellerOptions}
                  placeholder={
                    sellersErrorMessage
                      ? "Error loading sellers"
                      : sellersLoading
                      ? "Loading sellers..."
                      : ""
                  }
                  searchable={true}
                  value={sellerOptions.find(
                    (s) => s.value === sellerEnrollmentID
                  )}
                />
              </div>
            ) : (
              <div className="flex flex-align-center mb-8 mr-24 relative">
                <label className="accent-text small-text">Seller</label>
                <p className="small-text">
                  {
                    sellerOptions.find((s) => s.value === sellerEnrollmentID)
                      ?.label
                  }
                </p>
              </div>
            )}
            {editMode && (
              <RaffleOrderAddress
                address={order.address}
                countries={systemCountries}
                onChangeEventOrder={this.onChangeEventOrder}
                onChangeOrder={this.onChangeOrder}
              />
            )}
            <div>
              {isPrivate && !editMode && (
                <p className="small-text mt-16 accent-text-dark flex">
                  <i className="material-icons medium-text mr-4">
                    visibility_off
                  </i>
                  Do not acknowledge this donation publicly
                </p>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

import React, { forwardRef } from "react";
import { InputLabel, TextField, Typography } from "@material-ui/core";

export const TextInput = React.memo(
  forwardRef(function TextInput(
    {
      fullWidth = true,
      id,
      inputPrefix,
      InputProps,
      label,
      name,
      size = "small",
      variant = "outlined",
      ...passProps
    },
    ref,
  ) {
    return (
      <>
        {label && (
          <InputLabel error={passProps.error} htmlFor={id || name}>
            {label}
          </InputLabel>
        )}
        <TextField
          fullWidth={fullWidth}
          id={id || name}
          name={name}
          ref={ref}
          size={size}
          variant={variant}
          InputProps={{
            ...(variant !== "outlined" && { disableUnderline: true }),
            startAdornment: inputPrefix ? (
              <Typography color="primary" style={{ marginRight: "8px" }}>
                {inputPrefix}
              </Typography>
            ) : null,
            ...InputProps,
          }}
          {...passProps}
        />
      </>
    );
  }),
);

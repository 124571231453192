import AuthClient from "./AuthClient";
import queryString from "query-string";

const StudentsApi = {
  getStudent: async (cancelToken, studentId) => {
    const response = await AuthClient.get(
      `Students/${studentId}?includeInactive=true`,
      {
        cancelToken,
      },
    );
    return response.data || {};
  },
  searchStudents: async (cancelToken, searchQuery) => {
    const response = await AuthClient.get(
      `Students/Basic?${queryString.stringify({
        text: searchQuery,
        results: 25,
      })}`,
      {
        cancelToken,
      },
    );
    return response.data.payload || [];
  },
  getStudentActivity: async (
    cancelToken,
    studentId,
    page,
    results,
    rangeType,
    programType,
  ) => {
    const params = {
      includeInactive: true,
      page,
      rangeType,
      results,
      programType,
    };

    const response = await AuthClient.get(
      `Students/${studentId}/activity?${queryString.stringify(params)}`,
      { cancelToken },
    );
    return response.data.payload || {};
  },
  getStudentsByChabadHouse: async (cancelToken, chabadHouseId) => {
    const response = await AuthClient.get(
      `ChabadHouses/${chabadHouseId}/Students/Basic?isPrimary=false`,
      {
        cancelToken,
      },
    );
    return response.data.payload || [];
  },
  importStudents: async (cancelToken, studentsImport, onUploadProgress) => {
    const formData = new FormData();
    formData.append("file", studentsImport);

    await AuthClient.post("Students/bulkImport", formData, {
      cancelToken,
      "Content-Type": "multipart/form-data",
      onUploadProgress,
    });
  },
  getStudentsForExport: async (cancelToken) => {
    const response = await AuthClient.get("Students/Export", {
      cancelToken,
    });
    return response.data.payload || [];
  },
  compareStudents: async (cancelToken, ids) => {
    const response = await AuthClient.get(
      `Students/compare?${queryString.stringify({ ids })}`,
      {
        cancelToken,
      },
    );
    return response.data.payload || [];
  },
  mergeDuplicates: async (cancelToken, ids, student) => {
    const response = await AuthClient.post(
      `Students/mergeDuplicates?${queryString.stringify({ ids })}`,
      student,
      {
        cancelToken,
      },
    );
    return response.data.payload || {};
  },
  getDuplicatePeople: async (
    cancelToken,
    page,
    results,
    sortBy,
    search,
    studentId,
    hasActiveGrant,
  ) => {
    const params = {
      page,
      results,
      sortByOption: sortBy,
      studentId,
      text: search,
      hasActiveGrant,
    };
    const response = await AuthClient.get(
      `People/duplicatePeople?${queryString.stringify(params)}`,
      {
        cancelToken,
      },
    );
    return {
      duplicates: response.data.payload.results || [],
      totalDuplicates: response.data.payload.numberOfRows || 0,
    };
  },
  markNotDuplicate: async (id, cancelToken) => {
    const response = await AuthClient.post(
      `People/duplicatePeople/${id}/notDuplicate`,
      { cancelToken },
    );
    return response.data.payload || {};
  },
  submitStudent: async (student, cancelToken) => {
    const response = await AuthClient.post(`Students`, student, {
      cancelToken,
    });
    return response.data.payload || {};
  },
  getOtherPeople: async (
    listParams,
    cancelToken,
  ) => {
    const response = await AuthClient.get(
      `People/otherPeople?${queryString.stringify(listParams)}`,
      { cancelToken }
    );
    return {
      people: response.data.payload.results || [],
      totalPeople: response.data.payload.numberOfRows || 0,
    };
  },
  deleteOtherPersonContactMethod: async (otherPersonId, contactMethodId, cancelToken) => {
    await AuthClient.delete(
      `People/otherPeople/${otherPersonId}/contactMethod/${contactMethodId}`,
      { cancelToken },
    );
  },
};

export default StudentsApi;

import React from "react";
import PhoneInput from "../../shared/PhoneInput";

// import { ContactMethods } from "../../../models/Contact";

// interface ContactMethodProps {
//   contactMethod: ContactMethods;
//   handleChange: (newValue: ContactMethods) => void;
//   handleRemove: React.FormEventHandler<HTMLElement>;
// }

const ContactMethod = ({
  contactMethod,
  countries,
  handleChange,
  handleRemove,
}) => {
  const contactMethodOptions = [
    { value: "EmailAddress", displayText: "Email" },
    { value: "CellPhoneNumber", displayText: "Mobile" },
    { value: "PhoneNumber", displayText: "Phone" },
  ];
  /** @param {ContactMethods} contactMethodToUpdate
   * @param {string} property
   * @param {any} newValue
   * @returns {ContactMethods}
   */
  const updateProperty = (contactMethodToUpdate, property, newValue) => ({
    ...contactMethodToUpdate,
    [property]: newValue,
  });
  /** @returns {ContactMethods} */
  const getNewState = (evt) =>
    updateProperty(
      contactMethod,
      evt.currentTarget.type === "radio"
        ? evt.currentTarget.name.split("_")[0]
        : evt.currentTarget.name,
      evt.currentTarget.type === "radio"
        ? evt.currentTarget.value === "on"
        : evt.currentTarget.value,
    );

  const handleBasicDataChange = (evt) => handleChange(getNewState(evt));
  const handlePhoneInputChange = (phone, phoneCountry) => {
    handleChange(updateProperty(contactMethod, "value", phone));
    handleChange(updateProperty(contactMethod, "phoneCountryID", phoneCountry));
  };
  /** @param {string} type */
  const displayFromType = (type) =>
    contactMethodOptions.find((cmo) => cmo.value === type).displayText;

  return (
    <div className="react-phone-number-input__row">
      <select
        className="custom-input"
        name="type"
        value={contactMethod.type || ""}
        onChange={handleBasicDataChange}
        style={{ marginRight: "0.5em" }}
      >
        {contactMethodOptions.map((cm) => (
          <option key={cm.value} value={cm.value}>
            {cm.displayText}
          </option>
        ))}
      </select>
      {contactMethod.type === "EmailAddress" ? (
        <input
          className="custom-input"
          type={contactMethod.type === "EmailAddress" ? "email" : "phone"}
          name="value"
          value={contactMethod.value || ""}
          placeholder={`Enter ${displayFromType(contactMethod.type)} Address`}
          onChange={handleBasicDataChange}
          required={true}
        />
      ) : (
        <div className="flex flex-align-center">
          <PhoneInput
            className="phone-input"
            countries={countries || []}
            countryId={contactMethod.phoneCountryID || ""}
            name="value"
            onChange={handlePhoneInputChange}
            value={contactMethod.value || ""}
          />
          {contactMethod.type === "PhoneNumber" && (
            <div className="flex flex-align-center ml-8">
              <p>Ext</p>
              <input
                className="custom-input ml-4"
                name="phoneExtension"
                onChange={handleBasicDataChange}
                style={{ width: "60px" }}
                type="text"
                value={contactMethod.phoneExtension || ""}
              />
            </div>
          )}
        </div>
      )}
      <button
        className="btn btn-primary btn-sm"
        style={{ marginLeft: "0.5em" }}
        onClick={handleRemove}
      >
        <i className="glyphicon glyphicon-trash" />
      </button>
      <input
        style={{ marginLeft: "0.5em" }}
        type="radio"
        name={`primary_${contactMethod.type}`}
        checked={contactMethod.primary || false}
        onChange={handleBasicDataChange}
        required={true}
      />
      <span style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
        {" "}
        Primary {displayFromType(contactMethod.type)}
      </span>
    </div>
  );
};

export default ContactMethod;

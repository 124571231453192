import React from "react";
import { browserHistory } from "react-router";
import { Formik, Form } from "formik";
import { formatDateForInput, formatValuesForForm } from "../../../../lib/utils";
import AuthService, {
  PermissionClaims,
} from "../../../../services/AuthService";
import viewHistory from "../../../../services/ViewHistory";

import GeneralForm from "./general/GeneralForm";
import StudentOutreachForm from "./studentOutreach/StudentOutreachForm";

export default class ShliachForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      submitted: "",
      tabContentShowing: "",
    };
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.onNavigate);
    let { firstName, lastName } =
      (this.props.shliach && this.props.shliach.person) || {};
    viewHistory.add("Shliach", {
      title: firstName + " " + lastName,
      path: `/shluchim/${this.props.shliach.id}`,
    });
    let {
      router: {
        location: {
          query: { tab },
        },
      },
    } = this.props;
    if (tab === undefined) {
      tab = "general";
      this.navigate(tab);
    } else {
      this.setState({ tabContentShowing: tab });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      router: {
        location: {
          query: { tab },
        },
      },
    } = this.props;
    if (tab !== this.state.tabContentShowing) {
      this.setState({
        tabContentShowing: tab,
      });
    }
  }

  navigate = (page) => {
    browserHistory.replace(
      `${this.props.router.location.pathname}?tab=${page}`
    );
  };

  /** Router hook. See https://github.com/ReactTraining/react-router/blob/v3/docs/guides/ConfirmingNavigation.md */
  onNavigate = (/* nextLocation */) => {
    const form = document.getElementsByClassName("shliach-form")[0];
    if (!form.classList.contains("form-dirty")) {
      return;
    } else {
      return "Unsaved changes will be lost. Are you sure you want to leave?";
    }
  };

  validate = () => {
    const errors = {};
    return errors;
  };

  validateForm = (values) => {
    const { chabadHouses = [] } = values || {};
    const { contactMethods = [] } = values && values.person;
    const { contactMethods: contactMethodsSpouse } = values && values.spouse;
    const errors = {};
    if (contactMethods.length < 1) {
      errors.incompleteContactMethods = true;
    } else {
      let types = [];
      let primary = [];
      let preferred = [];
      for (let i = 0; i < contactMethods.length; i++) {
        types.push(contactMethods[i].type);
        if (contactMethods[i].isPrimary === true) {
          primary.push(contactMethods[i].isPrimary);
        }
        if (contactMethods[i].isPreferred === true) {
          preferred.push(contactMethods[i].isPreferred);
        }
      }
      if (
        !types.includes("Email") ||
        !primary.includes(true) ||
        !preferred.includes(true)
      ) {
        errors.incompleteContactMethods = true;
      } else {
        delete errors.incompleteContactMethods;
      }
      let typesSpouse = [];
      let primarySpouse = [];
      let preferredSpouse = [];
      if (contactMethodsSpouse) {
        for (let j = 0; j < contactMethodsSpouse.length; j++) {
          typesSpouse.push(
            contactMethodsSpouse[j] && contactMethodsSpouse[j].type
          );
          if (
            contactMethodsSpouse[j] &&
            contactMethodsSpouse[j].isPrimary === true
          ) {
            primarySpouse.push(
              contactMethodsSpouse[j] && contactMethodsSpouse[j].isPrimary
            );
          }
          if (
            contactMethodsSpouse[j] &&
            contactMethodsSpouse[j].isPreferred === true
          ) {
            preferredSpouse.push(
              contactMethodsSpouse[j] && contactMethodsSpouse[j].isPreferred
            );
          }
        }
        if (
          !typesSpouse.includes("Email") ||
          !primarySpouse.includes(true) ||
          !preferredSpouse.includes(true)
        ) {
          errors.incompleteSpouseContactMethods = true;
        } else {
          delete errors.incompleteSpouseContactMethods;
        }
      }
      let inputs = [].slice.call(
        document.getElementsByClassName("phone-input")
      );
      let invalidInputs = [];
      inputs.map((input) => {
        let invalidInput = input
          .getElementsByTagName("INPUT")[1]
          .hasAttribute("invalid");
        invalidInputs.push(invalidInput);
        return invalidInputs;
      });
      if (invalidInputs.includes(true)) {
        errors.invalidPhoneNumber = true;
      } else {
        delete errors.invalidPhoneNumber;
      }
    }

    if (
      chabadHouses &&
      chabadHouses.length &&
      chabadHouses.filter((ch) => ch.isPrimary).length !== 1
    ) {
      errors.noPrimaryChabadHouse = true;
    } else {
      delete errors.noPrimaryChabadHouse;
    }

    return errors;
  };

  render() {
    const {
      shliach,
      family,
      outreach,
      sys,
      submitError,
      saving,
      shliachDateFields,
    } = this.props;
    const { tabContentShowing } = this.state;
    let setNew = !this.props.routeParams.shliachID;
    let newShliach = {
      person: {
        firstName: "",
        lastName: "",
        nickname: "",
        hebrewName: "",
        title: "",
        gender: "",
        dob: "",
        beforeNightfall: "",
        contactMethods: [],
        hebrewDOB: "",
      },
      spouse: {
        firstName: "",
        lastName: "",
        nickname: "",
        hebrewName: "",
        title: "",
        gender: "",
        dob: "",
        beforeNightfall: "",
        contactMethods: [],
        hebrewDOB: "",
      },
      dateStarted: "",
      dateJoinedCOCI: formatDateForInput(new Date()), //default dateJoinedCOCI to today's date when shliach is being created
      family: {
        address: {
          address1: "",
          address2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        children: [],
      },
      outreach: {
        shliachOutreach: {
          //add shliach id
          shliachID: 0,
          category: "",
          demographics: [],
        },
        spouseOutreach: {
          //add shliach id
          shliachID: 0,
          category: "",
          demographics: [],
        },
      },
    };
    let readOnly = false;
    if (!AuthService.UserHasClaim(PermissionClaims.ShliachEdit)) {
      readOnly = true;
    }
    return (
      <Formik
        initialValues={
          setNew
            ? formatValuesForForm(newShliach, shliachDateFields)
            : formatValuesForForm(
                { ...shliach, family, outreach },
                shliachDateFields
              )
        }
        onSubmit={this.props.onSubmit}
        validate={this.validateForm}
      >
        {({
          values,
          dirty,
          errors,
          touched,
          handleChange,
          setFieldError,
          setFieldValue,
        }) => {
          const hasGeneralErrors =
            (errors.person ||
              errors.family ||
              errors.incompleteContactMethods ||
              errors.missingImageURL ||
              errors.invalidPhoneNumber ||
              errors.noPrimaryChabadHouse) &&
            this.props.errorMessage;
          const hasOutreachErrors =
            (errors.outreach ||
              errors.shliachDemographics ||
              errors.spouseDemographics) &&
            this.props.errorMessage;
          return (
            <Form
              style={{ display: "flex", width: "100%" }}
              className={`shliach-form ${dirty ? "form-dirty" : ""} ${
                readOnly ? "read-only" : ""
              }`}
            >
              <div className="card profile-form-card mr-24">
                <ul className="card-tabs-header profile-card-tabs-header">
                  <li>
                    <p
                      className={`${
                        tabContentShowing === "general" ? "active" : ""
                      } ${hasGeneralErrors ? "has-errors" : ""}`}
                      onClick={() => this.navigate("general")}
                    >
                      General
                    </p>
                  </li>
                  <li>
                    <p
                      className={`${
                        tabContentShowing === "outreach" ? "active" : ""
                      } ${hasOutreachErrors ? "has-errors" : ""}`}
                      onClick={() => this.navigate("outreach")}
                    >
                      Student Outreach
                    </p>
                  </li>
                </ul>

                {/* render these fields and hide with css when applicable so Formik is aware these fields exist - for error checking */}
                <div
                  className={
                    tabContentShowing === "outreach"
                      ? ""
                      : "desktop-hidden tablet-hidden mobile-hidden"
                  }
                >
                  <StudentOutreachForm
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    sys={sys}
                    touched={touched}
                    values={values}
                    shliach={shliach}
                  />
                </div>
                <div
                  className={
                    tabContentShowing === "general"
                      ? ""
                      : "desktop-hidden tablet-hidden mobile-hidden"
                  }
                >
                  <GeneralForm
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    sys={sys}
                    tab={tabContentShowing}
                    touched={touched}
                    values={values}
                  />
                </div>
              </div>

              {!readOnly ? (
                <div style={{ position: "relative" }}>
                  <div className="profile-save-btn-container">
                    <button
                      className="btn btn-accent profile-save-btn"
                      type="submit"
                      disabled={!dirty ? true : false}
                      onClick={() => this.props.checkForErrors(errors)}
                    >
                      {saving ? "Saving..." : "Save"}
                    </button>
                    {this.props.submitted === false ? (
                      <p className="error-message">
                        {submitError &&
                        submitError.data &&
                        submitError.status.toString()[0] === "4"
                          ? Object.values(submitError.data).map((e, index) => {
                              return (
                                <span key={index} className="error-message">
                                  {e.toString()}
                                </span>
                              );
                            })
                          : "Something went wrong. Please try again"}
                      </p>
                    ) : this.props.submitted && !dirty ? (
                      <p className="accent-text-dark">
                        <i className="material-icons">check</i> All changes
                        saved
                      </p>
                    ) : dirty ? (
                      <p className="accent-text-dark">
                        <i className="material-icons">save</i>
                        Unsaved changes
                      </p>
                    ) : null}
                    {this.props.errorMessage &&
                    Object.keys(errors).length > 0 ? (
                      <p className="error-message">
                        Please review all required fields
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

import React from "react";
import { Router, Route, browserHistory } from "react-router";
import { NotFound } from "./lib/coc-common-components";
import { App } from "./components/App";
import { Home } from "./components/Home";

import {
  IsraeLinksReporting,
  IsraeLinksReportingByStudent,
  IsraeLinksReportingBySchool,
} from "./components/IsraeLinks";

import {
  ShabbatonReporting,
  ShabbatonReportingBreakdownByStudent,
  ShabbatonReportingBreakdownBySchool,
} from "./components/Shabbaton";

import { KinusReporting } from "./components/Kinus/KinusReporting";
import { LamplightersReporting } from "./components/Lamplighters/LamplightersReporting";
import {
  ChayenuReporting,
  ChayenuReportingByStudent,
  ChayenuReportingBySchool,
} from "./components/Chayenu";
import {
  TefillinReporting,
  TefillinReportingByStudent,
  TefillinReportingBySchool,
} from "./components/Tefillin";

import { LifeInsuranceReporting } from "./components/LifeInsuranceReporting";
import { MediaAndPRReporting } from "./components/MediaAndPR/MediaAndPRReporting";
import { Students } from "./components/Contact/Students";
import { Shluchim } from "./components/Contact/Shluchim";
import { SchoolPageContainer } from "./components/School/SchoolPageContainer";
import StudentDetailsPage from "./components/Student/StudentDetailsPage";
import { ShliachPageContainer } from "./components/Contact/ShliachPages/ShliachPageContainer";
import ShliachDetailsPage from "./components/Shluchim/ShliachDetailsPage";
import { SchoolListPageContainer } from "./components/School/SchoolList/SchoolListPageContainer";
import Admins from "./components/User/Admins";
import Admin from "./components/User/Admin";
import Roles from "./components/User/Roles";
import Role from "./components/User/Role";
import AuthService from "./services/AuthService";
import ConfigQuickLinks from "./components/Config/ConfigQuickLinks";
import PortalRedirectsPage from "./components/Config/PortalRedirects/PortalRedirectsPage";
import CashTypesPage from "./components/Config/CashTypes/CashTypesPage";
import EventsListPageContainer from "./components/Events/CustomEventsList/EventsListPageContainer";
import CustomEventManagerPageContainer from "./components/Events/CustomEventsManager/CustomEventManagerPageContainer";
import AdvancedSearchContainer from "./components/AdvancedSearch/AdvancedSearchContainer";
import SummaryTemplateContainer from "./components/Events/ReportingTemplates/SummaryTemplateContainer";
import DetailedTemplateContactsContainer from "./components/Events/ReportingTemplates/DetailedTemplateContactsContainer";
import DetailedTemplateSchoolsContainer from "./components/Events/ReportingTemplates/DetailedTemplateSchoolsContainer";
import MergeDuplicatePage from "./components/Contact/MergeStudents/MergeDuplicatePage";
import MergeSuggestionsPage from "./components/Contact/MergeStudents/SuggestionsPage";
import OrganizationCategoryListPageContainer from "./components/Config/OrganizationCategory/OrganizationCategoryList/OrganizationCategoryListPageContainer";
import OrganizationCategoryManagerContainer from "./components/Config/OrganizationCategory/OrganizationCategoryManager/OrganizationCategoryManagerPageContainer";
import OrganizationListPageContainer from "./components/Organization/OrganizationList/OrganizationListPageContainer";
import OrganizationManagerContainer from "./components/Organization/OrganizationManager/OrganizationManagerPageContainer";
import { CustomPageListPageContainer } from "./components/CustomProgramManager/CustomPageList/CustomPageListPageContainer";
import { CustomPageManagerPageContainer } from "./components/CustomProgramManager/CustomPage/CustomPageManagerPageContainer";
import { ProgramsListPage } from "./components/Programs/ProgramsListPage";
import ProgramDetailsPage from "./components/Programs/ProgramDetails/ProgramDetailsPage";
import ProgramScheduleRegistrationsPage from "./components/Programs/ProgramDetails/ProgramSchedule/ProgramScheduleRegistrationsPage";
import ProgramScheduleApplicationSettingsPage from "./components/Programs/ProgramDetails/ProgramSchedule/ProgramScheduleApplicationSettingsPage";
import ProgramScheduleRegistrationDetailsPage from "./components/Programs/ProgramDetails/ProgramSchedule/registrations/RegistrationDetailsPage";

import TripEventConfigurationPage from "./components/TripEvents/TripEventConfigurationPage";
import TripEventPage from "./components/TripEvents/TripEventPage";
import TripEnrollmentPage from "./components/TripEvents/details/shluchim/shliachEnrollment/ShliachEnrollmentPage";
import TripRegistrationPage from "./components/TripEvents/details/students/studentRegistration/StudentRegistrationPage";

import Raffles from "./components/Raffle/RafflesPage";
import NewRaffle from "./components/Raffle/NewRafflePage";
import RaffleDetails from "./components/Raffle/RaffleDetailsPage";
import RaffleSettings from "./components/Raffle/RaffleSettingsPage";
import RaffleEnrollment from "./components/Raffle/RaffleEnrollmentPage";

import LifeInsurance from "./components/LifeInsurance/LifeInsurancePage";
import LifeInsuranceEnrollment from "./components/LifeInsurance/LifeInsuranceEnrollmentPage";

import EduProgramsPage from "./components/Edu/EduProgramsPage";
import EduProgramSettingsPage from "./components/Edu/EduProgramSettingsPage";
import EduCourseDetailsPage from "./components/Edu/courses/CourseDetailsPage";
import EduStudentProfilePage from "./components/Edu/students/student/StudentProfilePage";
import EduRewardDetailsPage from "./components/Edu/rewards/RewardDetailsPage";

import RsvpSystemPage from "./components/Rsvp/RsvpSystemPage";
import RsvpSystemSettingsPage from "./components/Rsvp/RsvpSystemSettingsPage";

import LamplightersPage from "./components/Lamplighters/LamplightersPage";
import LamplightersSettingsPage from "./components/Lamplighters/LamplightersSettingsPage";

import EngagementPortalPage from "./components/Engagement/EngagementPortalPage";

import OtherPeoplePage from "./components/Contact/OtherPeople/OtherPeoplePage";

const requireAuth = (_nextState, replace) => {
  if (!AuthService.isAuthenticated()) {
    replace({ pathname: "/" });
  }
};

export default class RouterApp extends React.Component {
  handleUpdate() {
    const { action } = this.state.location;
    if (action === "PUSH" || action === "REPLACE") {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <Router history={browserHistory} onUpdate={this.handleUpdate}>
        <Route component={App}>
          <Route path="/" component={Home} />
          <Route path="/shluchim" component={Shluchim} onEnter={requireAuth} />
          <Route path="/students" component={Students} onEnter={requireAuth} />
          <Route
            path="/schools"
            component={SchoolListPageContainer}
            onEnter={requireAuth}
          />
          <Route path="/users" component={Admins} onEnter={requireAuth} />
          <Route
            path="/organizations"
            component={OrganizationListPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/config"
            component={ConfigQuickLinks}
            onEnter={requireAuth}
          />

          <Route path="/config/roles" component={Roles} onEnter={requireAuth} />
          <Route
            path="/config/customevents"
            component={EventsListPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/config/orgcategories"
            component={OrganizationCategoryListPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/config/portalredirects"
            component={PortalRedirectsPage}
            onEnter={requireAuth}
          />
          <Route
            path="/config/cashtypes"
            component={CashTypesPage}
            onEnter={requireAuth}
          />

          <Route
            path="/shluchim/new"
            component={ShliachPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/shluchim/:shliachID/chabadHouseOptions"
            component={ShliachPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/shluchim/:shliachID/chabadHouse"
            component={ShliachPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/shluchim/:shliachID/school"
            component={ShliachPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/shluchim/:shliachID/details"
            component={ShliachDetailsPage}
            onEnter={requireAuth}
          />
          <Route
            path="/students/merge"
            component={MergeDuplicatePage}
            onEnter={requireAuth}
          />
          <Route
            path="/students/merge-suggestions"
            component={MergeSuggestionsPage}
            onEnter={requireAuth}
          />
          <Route
            path="/schools/new"
            component={SchoolPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/schools/:schoolID/chabadHouseOptions"
            component={SchoolPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/schools/:schoolID/chabadHouse"
            component={SchoolPageContainer}
            onEnter={requireAuth}
          />
          <Route path="/users/new" component={Admin} onEnter={requireAuth} />
          <Route
            path="/organizations/new"
            component={OrganizationManagerContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/config/roles/new"
            component={Role}
            onEnter={requireAuth}
          />
          <Route
            path="/config/customevents/new"
            component={CustomEventManagerPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/config/custompages/new"
            component={CustomPageManagerPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/config/orgcategories/new"
            component={OrganizationCategoryManagerContainer}
            onEnter={requireAuth}
          />

          <Route
            path="/shluchim/:shliachID"
            component={ShliachPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/shluchim/:shliachID/chabadHouse/:chabadHouseID"
            component={ShliachPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/shluchim/:shliachID/school/:campusID"
            component={ShliachPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/students/:id"
            component={StudentDetailsPage}
            onEnter={requireAuth}
          />
          <Route
            path="/schools/:schoolID"
            component={SchoolPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/schools/:schoolID/chabadHouse/:chabadHouseID"
            component={SchoolPageContainer}
            onEnter={requireAuth}
          />
          <Route path="/users/:id" component={Admin} onEnter={requireAuth} />
          <Route
            path="/organizations/:id"
            component={OrganizationManagerContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/config/roles/:id"
            component={Role}
            onEnter={requireAuth}
          />
          <Route
            path="/config/customevents/:id"
            component={CustomEventManagerPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/config/custompages/:id"
            component={CustomPageManagerPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/config/orgcategories/:id"
            component={OrganizationCategoryManagerContainer}
            onEnter={requireAuth}
          />

          <Route
            path="/israelinks"
            component={IsraeLinksReporting}
            onEnter={requireAuth}
          />
          <Route
            path="/israelinks/reporting"
            component={IsraeLinksReporting}
            onEnter={requireAuth}
          />
          <Route
            path="/israelinks/reporting/bystudent"
            component={IsraeLinksReportingByStudent}
            onEnter={requireAuth}
          />
          <Route
            path="/israelinks/reporting/byschool"
            component={IsraeLinksReportingBySchool}
            onEnter={requireAuth}
          />
          <Route
            path="/israelinks/reporting/bystudent/:id"
            component={IsraeLinksReportingByStudent}
            onEnter={requireAuth}
          />
          <Route
            path="/israelinks/reporting/byschool/:id"
            component={IsraeLinksReportingBySchool}
            onEnter={requireAuth}
          />

          <Route
            path="/shabbaton"
            component={ShabbatonReporting}
            onEnter={requireAuth}
          />
          <Route
            path="/shabbaton/reporting/bystudent"
            component={ShabbatonReportingBreakdownByStudent}
            onEnter={requireAuth}
          />
          <Route
            path="/shabbaton/reporting/byschool"
            component={ShabbatonReportingBreakdownBySchool}
            onEnter={requireAuth}
          />
          <Route
            path="/shabbaton/reporting/bystudent/:id"
            component={ShabbatonReportingBreakdownByStudent}
            onEnter={requireAuth}
          />
          <Route
            path="/shabbaton/reporting/byschool/:id"
            component={ShabbatonReportingBreakdownBySchool}
            onEnter={requireAuth}
          />

          <Route
            path="/kinus"
            component={KinusReporting}
            onEnter={requireAuth}
          />
          {/* <Route
            path="/lamplighters"
            component={LamplightersReporting}
            onEnter={requireAuth}
          /> */}
          <Route
            path="/lamplighters/reporting"
            component={LamplightersReporting}
            onEnter={requireAuth}
          />
          <Route
            path="/chayenu"
            component={ChayenuReporting}
            onEnter={requireAuth}
          />
          <Route
            path="/chayenu/reporting"
            component={ChayenuReporting}
            onEnter={requireAuth}
          />
          <Route
            path="/chayenu/reporting/bystudents"
            component={ChayenuReportingByStudent}
            onEnter={requireAuth}
          />
          <Route
            path="/chayenu/reporting/byschools"
            component={ChayenuReportingBySchool}
            onEnter={requireAuth}
          />
          <Route
            path="/tefillin"
            component={TefillinReporting}
            onEnter={requireAuth}
          />
          <Route
            path="/tefillin/reporting"
            component={TefillinReporting}
            onEnter={requireAuth}
          />
          <Route
            path="/tefillin/reporting/bystudents"
            component={TefillinReportingByStudent}
            onEnter={requireAuth}
          />
          <Route
            path="/tefillin/reporting/byschools"
            component={TefillinReportingBySchool}
            onEnter={requireAuth}
          />

          <Route path="/grand-draw" component={Raffles} onEnter={requireAuth} />
          <Route
            path="/grand-draw/new/:scheduleId"
            component={NewRaffle}
            onEnter={requireAuth}
          />
          <Route
            path="/grand-draw/settings/:scheduleId"
            component={RaffleSettings}
            onEnter={requireAuth}
          />
          <Route
            path="/grand-draw/:scheduleId"
            component={RaffleDetails}
            onEnter={requireAuth}
          />
          <Route
            path="/grand-draw/:scheduleId/enrollment/:enrollmentId"
            component={RaffleEnrollment}
            onEnter={requireAuth}
          />

          <Route
            path="/advancedsearch"
            component={AdvancedSearchContainer}
            onEnter={requireAuth}
          />

          <Route
            path="/lifeinsurance/reporting"
            component={LifeInsuranceReporting}
            onEnter={requireAuth}
          />
          <Route
            path="/mediapr"
            component={MediaAndPRReporting}
            onEnter={requireAuth}
          />
          <Route
            path="/mediapr/reporting"
            component={MediaAndPRReporting}
            onEnter={requireAuth}
          />

          <Route
            path="/evtreports/:id"
            component={SummaryTemplateContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/evtreports/:id/reporting/bycontact"
            component={DetailedTemplateContactsContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/evtreports/:id/reporting/byschool"
            component={DetailedTemplateSchoolsContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/config/custompages"
            component={CustomPageListPageContainer}
            onEnter={requireAuth}
          />
          <Route
            path="/programs"
            component={ProgramsListPage}
            onEnter={requireAuth}
          />
          <Route
            path="/programs/:id"
            component={ProgramDetailsPage}
            onEnter={requireAuth}
          />
          <Route
            path="/programs/:programId/schedules/:scheduleId/applicationSettings"
            component={ProgramScheduleApplicationSettingsPage}
            onEnter={requireAuth}
          />
          <Route
            path="/programs/:programId/schedules/:scheduleId/registrations"
            component={ProgramScheduleRegistrationsPage}
            onEnter={requireAuth}
          />
          <Route
            path="/programs/:programId/schedules/:scheduleId/registrations/:registrationId"
            component={ProgramScheduleRegistrationDetailsPage}
            onEnter={requireAuth}
          />

          <Route
            path="/programs/trips/:scheduleId"
            component={TripEventConfigurationPage}
            onEnter={requireAuth}
          />

          <Route
            path="/trips(/:scheduleId)"
            component={TripEventPage}
            onEnter={requireAuth}
          />
          <Route
            path="/trips/:scheduleId/enrollments/:enrollmentId"
            component={TripEnrollmentPage}
            onEnter={requireAuth}
          />
          <Route
            path="/trips/:scheduleId/students/:registrationId"
            component={TripRegistrationPage}
            onEnter={requireAuth}
          />

          <Route
            path="/edu(/:scheduleId)"
            component={EduProgramsPage}
            onEnter={requireAuth}
          />
          <Route
            path="/edu/:scheduleId/settings"
            component={EduProgramSettingsPage}
            onEnter={requireAuth}
          />
          <Route
            path="/edu/:scheduleId/courses(/:courseId)"
            component={EduCourseDetailsPage}
            onEnter={requireAuth}
          />
          <Route
            path="/edu/:scheduleId/students/:studentId"
            component={EduStudentProfilePage}
            onEnter={requireAuth}
          />
          <Route
            path="/edu/:scheduleId/rewards(/:rewardId)"
            component={EduRewardDetailsPage}
            onEnter={requireAuth}
          />

          <Route
            path="/life-insurance(/:scheduleId)"
            component={LifeInsurance}
            onEnter={requireAuth}
          />
          <Route
            path="/life-insurance/:scheduleId/enrollments/:enrollmentId"
            component={LifeInsuranceEnrollment}
            onEnter={requireAuth}
          />

          <Route
            path="/rsvp/settings(/:scheduleId)"
            component={RsvpSystemSettingsPage}
            onEnter={requireAuth}
          />
          <Route
            path="/rsvp(/:scheduleId)"
            component={RsvpSystemPage}
            onEnter={requireAuth}
          />

          <Route
            path="/lamplighters/settings/:scheduleId"
            component={LamplightersSettingsPage}
            onEnter={requireAuth}
          />
          <Route
            path="/lamplighters(/:scheduleId)"
            component={LamplightersPage}
            onEnter={requireAuth}
          />

          <Route
            path="/engagement(/:scheduleId)"
            component={EngagementPortalPage}
            onEnter={requireAuth}
          />

          <Route
            path="/other-people"
            component={OtherPeoplePage}
            onEnter={requireAuth}
          />

          {/*must be the last route*/}
          <Route path="*" component={NotFound} />
        </Route>
      </Router>
    );
  }
}
// // Allow Hot Module Reloading
// declare var module: any;
// if (module.hot) {
//     module.hot.accept();
// }

import React, { useCallback, useMemo, useState } from "react";
import debounce from "lodash.debounce";

export default function OtherPeopleTableFilters({
  applyFilter,
  filterText: _filterText,
}) {
  const [filterText, setFilterText] = useState(_filterText);

  const onFilterDebounce = useMemo(
    () => debounce(applyFilter, 500),
    [applyFilter]
  );

  const onChangeFilterText = useCallback(
    (e) => {
      setFilterText(e.target.value);
      onFilterDebounce(e.target.value);
    },
    [onFilterDebounce]
  );

  return (
    <div>
      <div className="search-input">
        <i className="material-icons accent-text-secondary flex flex-align-center ml-8">
          search
        </i>
        <input
          type="text"
          placeholder="Search"
          name="keyword"
          onChange={onChangeFilterText}
          value={filterText}
        />
      </div>
    </div>
  );
}

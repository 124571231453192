import _cloneDeep from "lodash.clonedeep";
import _get from "lodash.get";
import _set from "lodash.set";
import moment from "moment";

const usCountryCode = "us";

/** Converts an array of objects to an object with a key per id.
 * @param {{id: string}[]} arr */
export function arrayToObjectById(arr, mapItem = (item) => item) {
  const result = {};
  arr.forEach((item) => {
    result[item.id] = mapItem(item);
  });
  return result;
}
/** Transforms an object with to act like a Typescript enum by mirroring all
 * values as keys.
 * @template T
 * @param {T} values
 * @returns {T}
 * @see https://stackoverflow.com/questions/20278095/enums-in-typescript-what-is-the-javascript-code-doing
 *
 * Example:
 *  ```js
 *  // Given a `values` object defined like this:
 *  { A: 0, B: 1, C: 2 }
 *  // Transform it to contain all values as keys:
 *  { A: 0, B: 1, C: 2, 0: 'A', 1: 'B', 2: 'C' }
 *  ```
 */
export function asEnum(values) {
  for (let key in values) {
    values[values[key]] = key;
  }
  return values;
}

export function isNewIdParam(id) {
  return id === "new";
}

export function trimTrailingSlash(path) {
  if (path) {
    const { length } = path;
    if (path.charAt(length - 1) === "/") {
      return path.substr(0, length - 1);
    }
  }
  return path;
}

//returns an array with 2 nested arrays - the first is where the condition is true, the second is where the condition is false
export function partition(array, condition) {
  if (!array?.length) {
    return [[], []];
  }
  return [
    array.filter((a) => condition(a)),
    array.filter((b) => !condition(b)),
  ];
}

export function dateAddYears(date, yearsToAdd) {
  return new Date(date.setFullYear(date.getFullYear() + yearsToAdd));
}

export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function isNotValidDOB(date) {
  if (
    new Date(date) < dateAddYears(new Date(), -100) ||
    new Date(date) > new Date()
  )
    return "Invalid Date of Birth";
  return "";
}

export function formatValuesForForm(values, dateFields) {
  return iter(values, dateFields);
}

function iter(obj, dateFields) {
  Object.keys(obj).forEach(function (k) {
    if (obj[k] !== null && typeof obj[k] === "object") {
      if (k === "person" || k === "spouse") {
        Object.keys(obj[k]).forEach(function (i) {
          if (i === "contactMethods") {
            const contactMethods = obj[k][i] || [];
            if (contactMethods.map((cm) => cm.type).indexOf("Email") < 0) {
              contactMethods.push({
                isPrimary: true,
                isPreferred: true,
                type: "Email",
                value: "",
              });
            }
          }
        });
      }

      iter(obj[k], dateFields);
      return;
    }

    if (obj[k] === null) {
      obj[k] = "";
    } else if (
      dateFields &&
      dateFields.length &&
      dateFields.indexOf(k) >= 0 &&
      typeof obj[k] === "string" &&
      Date.parse(obj[k])
    ) {
      obj[k] = formatDateForInput(obj[k]);
    }

    if (k === "address" && !obj[k]) {
      obj[k] = {
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
      };
    }

    if (k === "email" && !obj[k]) {
      obj[k] = {
        type: "Email",
        value: "",
      };
    }
    if (k === "phone" && !obj[k]) {
      obj[k] = { type: "WorkNumber", value: "" };
    }
    if (k === "smsContactMethod" && !obj[k]) {
      obj[k] = { type: "MessagingNumber", value: "" };
    }
  });

  return obj;
}

export function formatAddressDisplay(
  address1,
  address2,
  city,
  state,
  zip,
  country
) {
  return (
    (address1 ? `${address1}, ` : "") +
    (address2 ? `${address2} ` : "") +
    (address1 || address2 ? "\n" : "") +
    (city ? `${city}, ` : "") +
    (state ? `${state} ` : "") +
    (zip ? `${zip}, ` : "") +
    (country ? `${country}` : "")
  );
}

export function formatDateForInput(dateString) {
  let date = "";
  if (dateString) {
    date = dateString;
    if (dateString.toString().length > 10) {
      var d = new Date(new Date(dateString)),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
  }
  return date;
}

export function formatDateTimeForInput(dateString) {
  let date = "";
  if (dateString) {
    date = dateString;
    if (dateString.toString().length > 10) {
      let d = new Date(new Date(dateString)),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        minutes = d.getMinutes(),
        seconds = d.getSeconds();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (hours.toString().length < 2) hours = "0" + hours;
      if (minutes.toString().length < 2) minutes = "0" + minutes;
      if (seconds.toString().length < 2) seconds = "0" + seconds;

      const dateTime =
        [year, month, day].join("-") +
        "T" +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      return dateTime;
    }
  }
  return date;
}

export const dateTimeWithSecondsFormat = "YYYY-MM-DDTHH:mm:ss";

export function removeTimezoneFormatFromDateStartOfDay(date) {
  return date ? removeTimezoneFormatFromDate(date.startOf("day")) : null;
}

export function removeTimezoneFormatFromDate(date) {
  //removing date timezone formatting to avoid server-side timezone conversion
  if (date && date.toString().indexOf("0") === 0) {
    //for manually entered dates - avoid formatting before entry is completed
    return date;
  }

  return date
    ? (moment.isMoment(date) ? date : moment(date)).format(
        dateTimeWithSecondsFormat
      )
    : null;
}

export const currencyCodes = {
  CAD: "CAD",
  GBP: "GBP",
  USD: "USD",
};

const currencySymbols = {
  CAD: "CAD $",
  GBP: "£",
  USD: "$",
};

export function getCurrencySymbol(currencyCode) {
  return currencySymbols[currencyCode] || "";
}

export function formatCurrency(num, currencyCode = "") {
  const currency = currencySymbols[currencyCode] || "";

  if (!num) {
    return `${currency}0`;
  }

  num = parseFloat(num).toFixed(2);
  var parts = num.toString().split(".");
  return (
    currency +
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "")
  );
}

export function formatAmount(num) {
  if (!num) {
    return "0";
  }

  num = parseFloat(num).toFixed(2);
  var parts = num.toString().split(".");
  return (
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "")
  );
}

export function formatExcelNumberString(numberString) {
  return numberString ? `="${numberString}"` : null;
}

export function formatFullName(firstName, lastName, middleName, title) {
  return [title !== "Unknown" && title, firstName, middleName, lastName]
    .filter((name) => name)
    .join(" ");
}

export function formatNumber(numString) {
  return numString
    ? numString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : 0;
}

export function getEndOfDay(date) {
  return moment(date).add(1, "days").subtract(1, "minutes");
}

export function getFormattedValuesForForm(values, dateFields) {
  const formattedValues = _cloneDeep(values);

  replaceValuesInObject(
    formattedValues,
    (val) => val === null,
    () => ""
  );

  if (dateFields) {
    dateFields.forEach((dateFieldName) => {
      const dateField = _get(formattedValues, dateFieldName);
      _set(formattedValues, dateFieldName, formatDateForInput(dateField));
    });
  }

  return formattedValues;
}

export function pluralizeText(text, count, altPluralization) {
  return count === 1 ? text : altPluralization || text + "s";
}

export function removeEmptyFromObj(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && obj[key] instanceof Array)
      obj[key].forEach(removeEmptyFromObj);
    else if (obj[key] && typeof obj[key] === "object")
      removeEmptyFromObj(obj[key]);
    else if (obj[key] === null || obj[key] === "") delete obj[key];
  });
}

export function replaceValuesInObject(obj, toReplace, replaceWith) {
  //toReplace: (value, key) => {}, replaceWith: (valueToReplace) => replacementValue
  Object.keys(obj).forEach((key) => {
    if (toReplace(obj[key], key)) {
      obj[key] = replaceWith(obj[key]);
    } else if (obj[key]) {
      if (obj[key] instanceof Array) {
        obj[key].forEach((rec) =>
          replaceValuesInObject(rec, toReplace, replaceWith)
        );
      } else if (obj[key] instanceof Object) {
        replaceValuesInObject(obj[key], toReplace, replaceWith);
      }
    }
  });
}

export function removePropFromObject(obj, prop) {
  Object.keys(obj).forEach((key) => {
    if (key === prop) {
      delete obj[key];
    } else if (obj[key]) {
      if (obj[key] instanceof Array) {
        obj[key].forEach((rec) => removePropFromObject(rec, prop));
      } else if (obj[key] instanceof Object) {
        removePropFromObject(obj[key], prop);
      }
    }
  });
}

export function flattenArray(arr) {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(
      Array.isArray(toFlatten) ? flattenArray(toFlatten) : toFlatten
    );
  }, []);
}

export function slugify(str, trimTrailingSlashes) {
  if (str) {
    str = str.toLowerCase();

    // Remove all accents
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    str = str
      .replace(/[^a-z0-9 -]/g, " ") // replace invalid chars with whitespace
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    if (trimTrailingSlashes) {
      str = str.replace(/^-+|-+$/g, ""); // remove leading, trailing -
    }
  }

  return str;
}

// get a versioned image source path when image at static path is updated
export function getVersionedImgSrc(fileName) {
  return `/img/${fileName}?${process.env.REACT_APP_IMG_CACHE_VERSION}`;
}

export function getYearOptions(startYear, endYear) {
  const numberOfYears = endYear - startYear + 1;
  return [...Array(numberOfYears).keys()].map((i) => i + startYear);
}

export function sum(vals) {
  return vals.reduce((total, val) => total + parseFloat(val || 0), 0);
}

export function parseNumber(numString) {
  return numString ? parseFloat(numString.toString().replace(/,/g, "")) : "";
}

export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export function splitCamelCase(string) {
  return string ? string.replace(/([a-z])([A-Z])/g, "$1 $2") : "";
}

export class TwoWayMap {
  constructor(map) {
    this.map = map;
    this.reverseMap = {};
    for (let key in map) {
      const value = map[key];
      this.reverseMap[value] = key;
    }
  }
  get(key) {
    return this.map[key];
  }
  revGet(key) {
    return this.reverseMap[key];
  }
}

/**
 * Load document script
 * Primary source for this function https://stackoverflow.com/a/72210710/12892971
 */
export function loadScript(src, async = true, defer = true) {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve(true);

    const script = document.createElement("script");
    script.src = src;
    script.async = async;
    script.defer = defer;
    script.onload = () => resolve(true);
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });
}
export function getDefaultCountryCode(
  countries,
  countryId,
  defaultCountryName
) {
  const countryCode = countryId
    ? getCountryCodeById(countryId, countries)
    : defaultCountryName
    ? getCountryCodeByName(defaultCountryName, countries)
    : null;
  return countryCode || usCountryCode;
}

export function getCountryIdByCode(countryCode, countries) {
  const country = countries.find(
    (c) => c.code.toLowerCase() === countryCode.toLowerCase()
  );
  return country?.id || null;
}

export function getCountryCodeById(countryId, countries) {
  const country = countries.find((c) => c.id === countryId);
  return country?.code.toLowerCase() || null;
}

export function getCountryCodeByName(countryName, countries) {
  if (countryName === "Scotland") countryName = "United Kingdom"; //note: no country support for scotland, use UK instead
  const country = countries.find((c) => c.name === countryName);
  return country?.code.toLowerCase() || usCountryCode;
}
